import React from 'react';
import { Layout } from 'features/common/components/Layout';
import { Card, CardContent, CardMedia, Typography, Grid, Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { isOperatorOrHigher, isAdminOrSuperAdmin } from '../../features/auth/helpers';
import { CountryCodeEnum } from '../../features/common/types';

const cardData = [
	{
		id: 1,
		icon: '📝',
		title: 'Corrección de Nombre',
		description: 'Solicita la corrección de un nombre en una reserva ya emitida',
		link: 'https://crm.nimbux.tech/support/oper-correcci-n-de-nombre',
		isExternal: true,
		allowedCountries: [
			CountryCodeEnum.URUGUAY,
			CountryCodeEnum.PARAGUAY,
			CountryCodeEnum.BOLIVIA,
			CountryCodeEnum.COLOMBIA,
			CountryCodeEnum.TN_ITAU_PY,
		],
		visibleTo: 'all',
	},
	{
		id: 2,
		icon: '🛄',
		title: 'Cotización de Adicionales',
		description: 'Cotiza ancilliaries como equipaje, asientos, comidas y más, En proveedores SIN ACCESO',
		link: 'https://crm.nimbux.tech/support/oper-cotizaci-n-de-adicionales',
		isExternal: true,
		allowedCountries: [
			CountryCodeEnum.URUGUAY,
			CountryCodeEnum.PARAGUAY,
			CountryCodeEnum.BOLIVIA,
			CountryCodeEnum.COLOMBIA,
			CountryCodeEnum.TN_ITAU_PY,
		],
		visibleTo: 'all',
	},
	{
		id: 3,
		icon: '🔄',
		title: 'Cotización de Cambios Voluntarios',
		description: 'Cotiza modificaciones en vuelos de reservas emitidas en proveedores SIN ACCESO.',
		link: 'https://crm.nimbux.tech/support/oper-cotizaci-n-de-cambios-voluntarios',
		isExternal: true,
		allowedCountries: [
			CountryCodeEnum.URUGUAY,
			CountryCodeEnum.PARAGUAY,
			CountryCodeEnum.BOLIVIA,
			CountryCodeEnum.COLOMBIA,
			CountryCodeEnum.TN_ITAU_PY,
		],
		visibleTo: 'all',
	},
	{
		id: 4,
		icon: '⚠️',
		title: 'Confirmar Cambios Involuntarios',
		description: 'Registra cambios no voluntarios en reservas de proveedores sin acceso.',
		link: 'https://crm.nimbux.tech/support/oper-confirmar-cambios-involuntarios',
		isExternal: true,
		allowedCountries: [
			CountryCodeEnum.URUGUAY,
			CountryCodeEnum.PARAGUAY,
			CountryCodeEnum.BOLIVIA,
			CountryCodeEnum.COLOMBIA,
			CountryCodeEnum.TN_ITAU_PY,
		],
		visibleTo: 'all',
	},
	{
		id: 5,
		icon: '📜',
		title: 'Consulta sobre Reglas Tarifarias',
		description: 'Solicita información detallada sobre reglas tarifarias para cambios o devoluciones.',
		link: 'https://crm.nimbux.tech/support/oper-consulta-sobre-reglas-tarifarias',
		isExternal: true,
		allowedCountries: [
			CountryCodeEnum.URUGUAY,
			CountryCodeEnum.PARAGUAY,
			CountryCodeEnum.BOLIVIA,
			CountryCodeEnum.COLOMBIA,
			CountryCodeEnum.TN_ITAU_PY,
		],
		visibleTo: 'all',
	},
	{
		id: 6,
		icon: '👥',
		title: 'Cotizar Grupo',
		description: 'Cotiza reservas para grupos de 10 pasajeros o más.',
		link: 'https://crm.nimbux.tech/support/oper-cotizar-grupo',
		isExternal: true,
		allowedCountries: [
			CountryCodeEnum.URUGUAY,
			CountryCodeEnum.PARAGUAY,
			CountryCodeEnum.BOLIVIA,
			CountryCodeEnum.COLOMBIA,
			CountryCodeEnum.TN_ITAU_PY,
		],
		visibleTo: 'all',
	},
	{
		id: 7,
		icon: '♿',
		title: 'Solicitud de Servicios Especiales',
		description:
			'Solicita servicios especiales sin costo (excepto ancilliaries). Por ejemplo: Silla de ruedas, menor acompañado, etc...',
		link: 'https://crm.nimbux.tech/support/oper-solicitud-de-servicios-especiales-sin-costo',
		isExternal: true,
		allowedCountries: [
			CountryCodeEnum.URUGUAY,
			CountryCodeEnum.PARAGUAY,
			CountryCodeEnum.BOLIVIA,
			CountryCodeEnum.COLOMBIA,
			CountryCodeEnum.TN_ITAU_PY,
		],
		visibleTo: 'all',
	},
	{
		id: 8,
		icon: '🔓',
		title: 'Solicitud de Apertura de Billete',
		description: 'Solicita la apertura de un billete cuando por ejemplo el pax no viaja y aun sabe cuando lo hará.',
		link: 'https://crm.nimbux.tech/support/oper-solicitud-de-apertura-de-billete',
		isExternal: true,
		allowedCountries: [
			CountryCodeEnum.URUGUAY,
			CountryCodeEnum.PARAGUAY,
			CountryCodeEnum.BOLIVIA,
			CountryCodeEnum.COLOMBIA,
			CountryCodeEnum.TN_ITAU_PY,
		],
		visibleTo: 'all',
	},
	{
		id: 9,
		icon: '❌',
		title: 'Voidear Boleto',
		description: 'Solicita la anulación de un boleto emitido en el día',
		link: 'https://crm.nimbux.tech/support/oper-voideo',
		isExternal: true,
		allowedCountries: [
			CountryCodeEnum.URUGUAY,
			CountryCodeEnum.PARAGUAY,
			CountryCodeEnum.BOLIVIA,
			CountryCodeEnum.COLOMBIA,
			CountryCodeEnum.TN_ITAU_PY,
		],
		visibleTo: 'all',
	},
	{
		id: 10,
		icon: '✈️',
		title: 'Cotizar Vuelos',
		description: 'Solicita cotización de vuelos cuando no sea posible hacerlo en las plataformas disponibles.',
		link: 'https://crm.nimbux.tech/support/oper-cotizar-vuelos',
		isExternal: true,
		allowedCountries: [
			CountryCodeEnum.URUGUAY,
			CountryCodeEnum.PARAGUAY,
			CountryCodeEnum.BOLIVIA,
			CountryCodeEnum.COLOMBIA,
			CountryCodeEnum.TN_ITAU_PY,
		],
		visibleTo: 'all',
	},
	{
		id: 11,
		icon: 'ℹ️',
		title: 'Info Aerolinas',
		description: 'Condiciones con aerolineas e info util para la cotización',
		link: 'https://docs.google.com/spreadsheets/d/1VNC_NgRQWjhY885q0sGHccE5cadTRH0C7pL9c2I6_ro/edit?usp=sharing',
		isExternal: true,
		allowedCountries: [
			CountryCodeEnum.URUGUAY,
			CountryCodeEnum.PARAGUAY,
			CountryCodeEnum.BOLIVIA,
			CountryCodeEnum.COLOMBIA,
			CountryCodeEnum.TN_ITAU_PY,
		],
		visibleTo: 'all',
	},
];

const AirOperations = (): JSX.Element => {
	const { auth } = useSelector((state: RootState) => state);

	const filteredCards = cardData.filter(
		(card) =>
			card.allowedCountries.includes(auth.country) &&
			(card.visibleTo === 'all' ||
				(card.visibleTo === 'operator' && isOperatorOrHigher(auth.user)) ||
				(card.visibleTo === 'admin' && isAdminOrSuperAdmin(auth.user))),
	);
	return (
		<Layout>
			<>
				<Box sx={{ flexGrow: 1, padding: 2, margin: '0 20%' }}>
					<RouterLink to="/home" style={{ textDecoration: 'none' }}>
						<Typography mb={2}>Click para volver al HOME</Typography>
					</RouterLink>
					<Grid container spacing={2} justifyContent="center">
						{filteredCards.map((card) => (
							<Grid item xs={12} sm={6} md={4} lg={3} key={card.id}>
								{card.isExternal ? (
									<a href={card.link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
										<Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', maxWidth: '240' }}>
											<CardMedia
												component="div"
												sx={{ padding: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
											>
												<Typography variant="h4">{card.icon}</Typography>
											</CardMedia>
											<CardContent sx={{ flexGrow: 1 }}>
												<Typography variant="subtitle1" component="div">
													{card.title}
												</Typography>
												<Typography variant="body2" color="text.secondary">
													{card.description}
												</Typography>
											</CardContent>
										</Card>
									</a>
								) : (
									<RouterLink to={card.link} style={{ textDecoration: 'none' }}>
										<Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', maxWidth: '240' }}>
											<CardMedia
												component="div"
												sx={{ padding: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
											>
												<Typography variant="h4">{card.icon}</Typography>
											</CardMedia>
											<CardContent sx={{ flexGrow: 1 }}>
												<Typography variant="subtitle1" component="div">
													{card.title}
												</Typography>
												<Typography variant="body2" color="text.secondary">
													{card.description}
												</Typography>
											</CardContent>
										</Card>
									</RouterLink>
								)}
							</Grid>
						))}
					</Grid>
				</Box>
			</>
		</Layout>
	);
};

export default AirOperations;

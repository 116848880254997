import React from 'react';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

interface Props {
	showButton: boolean;
	disabled: boolean;
	handleClick: () => void;
}

const FlightServiceIssueRequestButton: React.FC<Props> = (props: Props) => {
	const { showButton, disabled, handleClick } = props;
	return (
		<>
			{showButton && (
				<Box display="flex" alignItems="center">
					<Button
						type="button"
						disabled={disabled}
						variant="outlined"
						color="primary"
						onClick={handleClick}
						sx={{
							marginRight: disabled ? 0 : undefined, // Elimina cualquier separación extra cuando se muestra el ícono
						}}
					>
						Solicitar Emisión
					</Button>
					{disabled && (
						<Tooltip
							title="Para poder solicitar la emisión del vuelo, el servicio debe haberse guardado previamente, debe tener una referencia válida (No vacía ni igual a '-') y debe tener segmentos cargados."
							arrow
						>
							<IconButton
								color="primary"
								sx={{
									padding: 0, // Reduce el espacio alrededor del ícono
								}}
							>
								<HelpOutlineIcon />
							</IconButton>
						</Tooltip>
					)}
				</Box>
			)}
		</>
	);
};

export default FlightServiceIssueRequestButton;

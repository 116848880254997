import React, { useEffect, useRef } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import {
	Air,
	AirSimple,
	DestinationSimple,
	DestinationSummaryForDestinationMultiple,
	QuotationDestinationMultipleContainer,
	QuotationServiceSimple,
} from '../../types';
import { GetPAXTotalsResponse } from '../../helpers';
//import {calculateTotalNights } from '../../helpers';
import { QuotationIntroPreview } from './QuotationIntroPreview';
import { QuotationFinalPricePreview } from './QuotationFinalPricePreview';
import PaymentMethods from '../PaymentMethods/PaymentMethods';
import Footer from '../Footer/Footer';
import ImportantInformation from '../ImportantInformation';
import { AirDisplay } from '../AirConfig/AirDisplay';
import { DestinationOptionalServiceList } from '../DestinationOptionalServiceList';
import { MultiSelectHotelsMultiDestination } from '../MultiSelectHotelsMultiDestination';
import { QuotationServicesObservationPreview } from './QuotationServicesObservationPreview';
import { QuotationsConditions } from '../Accordion/QuotationConditions';
//import SeguroAsistencia from '../TravelAssistance';

// @ts-ignore
const useStyles = makeStyles((theme) => ({
	root: {
		lexGrow: 1,
	},
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
	},
	itineraryMobileTitle: {
		fontStyle: 'normal',
		fontWeight: 'bold',
		letterSpacing: '0em',
		textAlign: 'center',
		color: theme.palette.primary.main,
		marginBottom: theme.spacing(3),
	},
	presupuestoTitle: {
		fontStyle: 'normal',
		fontWeight: 'bold',
		letterSpacing: '0em',
		textAlign: 'center',
		color: theme.palette.primary.main,
	},
}));

interface Props {
	destinationsForQuotationSimpleDestination: DestinationSimple[] | null;
	containerMultiDestination: QuotationDestinationMultipleContainer | null;
	isMultiDestination: boolean;
	paxTotals: GetPAXTotalsResponse;
	manualServices: QuotationServiceSimple[];
	notIncludes: string[];
	description: string;
	countryCode: string;
	airs: Air[] | AirSimple[];
	flightIndexSelected: number | null;
	showFlightSelectionControl?: boolean;
	handleFlightSelectionChange?: (index: number | null) => void;
	exchangeRate: number;
	localCurency: string;
	showLocalCurrency?: boolean;
	quotationId: number;
	ntsQuantity?: number;
}

export default function QuotationPreview(props: Props): JSX.Element {
	const classes = useStyles();

	const destinationsToUse =
		props.isMultiDestination && props.containerMultiDestination != null
			? props.containerMultiDestination.destinations
			: props.destinationsForQuotationSimpleDestination || [];

	// const totalNights = calculateTotalNights(destinationsToUse);

	const flightsRef = useRef([]);

	const handleFlightRef = (element: HTMLDivElement | null, index: number) => {
		// @ts-ignore
		flightsRef.current[index] = element;
	};
	useEffect(() => {
		if (
			props.airs.length > 1 &&
			props.flightIndexSelected != null &&
			flightsRef.current[props.flightIndexSelected] != null
		) {
			// @ts-ignore
			flightsRef.current[props.flightIndexSelected].scrollIntoView({ behavior: 'smooth' });
		}
	}, [props.flightIndexSelected]);

	useEffect(() => {
		// para cuando sea de un solo destino y multiples vuelos se seleccione el vuelo de la opción más económica
		if (
			props.airs.length > 1 &&
			props.destinationsForQuotationSimpleDestination != null &&
			props.destinationsForQuotationSimpleDestination.length == 1 &&
			props.handleFlightSelectionChange != null
		) {
			const newHotelOptionsToSort = [...props.destinationsForQuotationSimpleDestination[0].hotelOptions];
			const priceCombinationsSorted = newHotelOptionsToSort.sort(function (a, b) {
				return (a.rooms[0]?.totalPrice || 0) - (b.rooms[0]?.totalPrice || 0);
			});
			props.handleFlightSelectionChange(priceCombinationsSorted[0].flightIndex ?? null);
		}
	}, []);

	const manualServicesWithDescription = props.manualServices.filter(
		(service) => service.description != null && service.description != '',
	);

	const servicesWithDescriptionInDestinations = destinationsToUse
		// @ts-ignore
		.map((destination: DestinationSimple | DestinationSummaryForDestinationMultiple) =>
			destination.services.filter((service) => service.description != null && service.description != ''),
		)
		// @ts-ignore
		.reduce((a, b) => a.concat(b), []);

	return (
		<>
			<QuotationIntroPreview
				hasHotels={true}
				description={props?.description}
				destinations={destinationsToUse}
				manualServices={props.manualServices}
				notIncludes={props.notIncludes}
				hasAir={props.airs.length > 0}
			/>

			{props.airs.length == 1 && (
				<Container component="main" maxWidth="md">
					<div className={classes.paper}>
						<Typography
							component="h1"
							variant="h6"
							sx={{
								marginBottom: '8px',
								width: '100%',
								backgroundColor: '#31194B',
								paddingLeft: '16px',
								paddingRight: '16px',
								paddingTop: '8px',
								paddingBottom: '8px',
								borderRadius: '4px',
								fontSize: '1rem',
								color: 'white',
							}}
						>
							Este es el itinerario de vuelos sugerido:
						</Typography>
						<AirDisplay data={props.airs[0]} previewMode={true} index={0} />
					</div>
				</Container>
			)}
			{props.airs.length > 1 && (
				<Container component="main" maxWidth="md">
					<div className={classes.paper}>
						<Typography
							component="h1"
							variant="h6"
							sx={{
								marginBottom: '8px',
								width: '100%',
								backgroundColor: '#31194B',
								paddingLeft: '16px',
								paddingRight: '16px',
								paddingTop: '8px',
								paddingBottom: '8px',
								borderRadius: '4px',
								fontSize: '1rem',
								color: 'white',
							}}
						>
							Selecciona la opción de vuelos que desees:
						</Typography>
						<Typography
							variant="body2"
							sx={{
								paddingLeft: '16px',
								paddingRight: '16px',
								paddingBottom: '8px',
							}}
						>
							* La tarifa de tu paquete varía automaticamente en función del itinerario elegido.
						</Typography>
						<Container disableGutters sx={{ overflow: 'auto', paddingBottom: 1 }}>
							<Grid
								spacing={0}
								wrap="nowrap"
								p={0}
								m={0}
								container
								minWidth={props.airs.length * 350}
								sx={{ display: 'flex', justifyContent: 'space-between' }}
							>
								{/*// @ts-ignore*/}
								{props.airs.map((air: Air | AirSimple, index: number) => {
									return (
										<Grid
											ref={(element) => handleFlightRef(element, index)}
											key={index}
											sx={{ display: 'flex', alignItems: 'stretch', flex: 1 }}
											pr={1}
											item
										>
											{' '}
											<AirDisplay
												data={air}
												previewMode={true}
												index={index}
												handleSelectionChange={props.handleFlightSelectionChange}
												showSelectionControl={props.showFlightSelectionControl}
												indexSelected={props.flightIndexSelected}
											/>
										</Grid>
									);
								})}
							</Grid>
						</Container>
					</div>
				</Container>
			)}
			<Container component="main" maxWidth="md">
				<div className={classes.paper}>
					{!props.isMultiDestination && props.destinationsForQuotationSimpleDestination && (
						<>
							<Typography
								component="h1"
								variant="h6"
								sx={{
									marginBottom: '8px',
									width: '100%',
									backgroundColor: '#31194B',
									paddingLeft: '16px',
									paddingRight: '16px',
									paddingTop: '8px',
									paddingBottom: '8px',
									borderRadius: '4px',
									fontSize: '1rem',
									color: 'white',
								}}
							>
								Presupuesto:
							</Typography>
							<QuotationFinalPricePreview
								exchangeRate={props.exchangeRate}
								localCurrency={props.localCurency}
								showLocalCurrency={props.showLocalCurrency}
								totalFlights={props.airs.length}
								flightIndexSelected={props.flightIndexSelected}
								destinations={props.destinationsForQuotationSimpleDestination}
								paxTotals={props.paxTotals}
								showUtility={false}
								markup={null}
								quotationId={props.quotationId}
							/>{' '}
						</>
					)}
					{props.isMultiDestination && props.containerMultiDestination && (
						<>
							<Typography
								component="h1"
								variant="h6"
								sx={{
									marginBottom: '8px',
									width: '100%',
									backgroundColor: '#31194B',
									paddingLeft: '16px',
									paddingRight: '16px',
									paddingTop: '8px',
									paddingBottom: '8px',
									borderRadius: '4px',
									fontSize: '1rem',
									color: 'white',
								}}
							>
								Selecciona el alojamiento para cada destino:
							</Typography>
							<Typography
								variant="body2"
								sx={{
									paddingLeft: '16px',
									paddingRight: '16px',
									paddingBottom: '8px',
								}}
							>
								* La tarifa de tu paquete varía automaticamente en función del alojamiento elegido para cada destino.
							</Typography>
							<MultiSelectHotelsMultiDestination
								exchangeRate={props.exchangeRate}
								localCurrency={props.localCurency}
								showLocalCurrency={props.showLocalCurrency}
								destinations={props.containerMultiDestination.destinations}
								priceCombinations={props.containerMultiDestination.priceCombinations}
								paxTotals={props.paxTotals}
								manualServices={props.manualServices}
								showUtility={false}
								markup={null}
								totalFlights={props.airs.length}
								flightIndexSelected={props.flightIndexSelected}
								handleFlightSelectionChange={props.handleFlightSelectionChange}
								quotationId={props.quotationId}
							/>{' '}
						</>
					)}
				</div>
			</Container>
			{(manualServicesWithDescription.length > 0 || servicesWithDescriptionInDestinations.length > 0) && (
				<QuotationServicesObservationPreview
					services={servicesWithDescriptionInDestinations}
					manualServices={manualServicesWithDescription}
				/>
			)}
			<DestinationOptionalServiceList destinations={destinationsToUse} paxTotals={props.paxTotals} isPreview={true} />
			{/* Dejo apagado el seguro de asistencia hasta capacitar */}
			{/* <SeguroAsistencia ntsQuantity={totalNights} /> */}

			<PaymentMethods country={props.countryCode} />
			<QuotationsConditions />
			<ImportantInformation country={props.countryCode} />
			<Footer country={props.countryCode} />
		</>
	);
}

import React from 'react';
import {
	Alert,
	AlertColor,
	Backdrop,
	Button,
	Card,
	CardContent,
	CircularProgress,
	Grid,
	Snackbar,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles/makeStyles';
import { extractErrorMessage, isNotFoundError } from 'features/quotation/helpers';
import { useEffect, useState } from 'react';
import { formatDateTimeToShowUser, formatDateToShowUser } from 'features/common/helpers';
import { getAuthorizationStateName } from 'features/transactions/helpers';
import { CrossStateEnum, IAuthorizationDetails, IEticketUnbindInfoAllowed } from 'features/authorization/types';
import { getAuthorizationDetails, getUnbindEticketsAllowed } from 'features/authorization/services';
import { getCrossOperatorName, getCrossStateName } from 'features/authorization/helpers';
import { CreateETicketModal } from '../CreateETicketModal';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PaidIcon from '@mui/icons-material/Paid';
import { ChargedAgainstOptionTypeEnum, IOperators } from 'features/transactions/types';
import { getOperators } from 'features/transactions/services';
import { CreateUnbindModal } from '../CreateUnbindModal';
import { PageInternalError404, ErrorType } from '../../../common/components/PageInternalError404';

interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

interface AuthorizationDetailsProps {
	id: number;
}

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
	cardItem: {
		backgroundColor: theme.palette.primary.main,
		color: 'white',
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'center',
		justifyContent: 'center',
	},
	tableHeader: {
		backgroundColor: theme.palette.primary.main,
		color: 'white',
	},
}));

export const AuthorizationDetails = ({ id }: AuthorizationDetailsProps): JSX.Element => {
	const classes = useStyles();
	const [loading, setLoading] = useState<boolean>(false);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
	const [authorizationDetails, setAuthorizationDetails] = useState<IAuthorizationDetails | null>(null);
	const [refundableETickets, setRefundableETickets] = useState<IEticketUnbindInfoAllowed[]>([]);
	const [operators, setOperators] = useState<IOperators[]>([]);
	const [createRefundModal, setCreateRefundModal] = useState<boolean>(false);
	const [createETicketModal, setCreateETicketModal] = useState<boolean>(false);
	const [showError404, setShowError404] = useState<boolean>(false);

	const getDetails = async () => {
		try {
			setLoading(true);
			setAuthorizationDetails((await getAuthorizationDetails(id)).data);
			setRefundableETickets((await getUnbindEticketsAllowed(id)).data);
			setLoading(false);
		} catch (error) {
			if (isNotFoundError(error)) {
				setLoading(false);
				setShowError404(true);
			} else {
				setAlert({
					show: true,
					severity: 'error',
					message: extractErrorMessage(error, 'Ocurrió un error al recibir los detalles de la autorización'),
				});
				setLoading(false);
			}
		}
	};

	const getOperatorsInfo = async () => {
		try {
			setLoading(true);
			setOperators((await getOperators()).data);
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al pedir los operadores'),
			});
			setLoading(false);
		}
	};

	useEffect(() => {
		getDetails();
	}, []);

	useEffect(() => {
		getOperatorsInfo();
	}, []);

	if (showError404) {
		return <PageInternalError404 errorType={ErrorType.INTERNAL_AUTHORIZATION_NOT_FUND} />;
	}

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>

			{authorizationDetails && (
				<>
					<>
						{' '}
						<Grid container gap={2}>
							<Grid item xs={12}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<Grid container spacing={1}>
											<Grid item md={4} xs={12}>
												<Card sx={{ minWidth: 275 }}>
													<CardContent>
														<Typography variant="h5" component="div">
															ID de Autorización
														</Typography>
														<Typography variant="body2">{authorizationDetails.authorizationData.id}</Typography>
													</CardContent>
												</Card>
											</Grid>
											<Grid item md={4} xs={12}>
												<Card sx={{ minWidth: 275 }}>
													<CardContent>
														<Typography variant="h5" component="div">
															Cobrado contra
														</Typography>
														<Typography variant="body2">
															{authorizationDetails.authorizationData.chargedAgainstOptionName}
														</Typography>
													</CardContent>
												</Card>
											</Grid>

											<Grid item md={4} xs={12}>
												<Card sx={{ minWidth: 275 }}>
													<CardContent>
														<Typography variant="h5" component="div">
															Cobrado por
														</Typography>
														<Typography variant="body2">{authorizationDetails.authorizationData.createdBy}</Typography>
													</CardContent>
												</Card>
											</Grid>
											<Grid item md={4} xs={12}>
												<Card sx={{ minWidth: 275 }}>
													<CardContent>
														<Typography variant="h5" component="div">
															Monto Autorizado
														</Typography>
														<Typography variant="body2">
															{authorizationDetails.authorizationData.amountAuthorized}{' '}
															{authorizationDetails.authorizationData.coinCode}
														</Typography>
													</CardContent>
												</Card>
											</Grid>

											<Grid item md={4} xs={12}>
												<Card sx={{ minWidth: 275 }}>
													<CardContent>
														<Typography variant="h5" component="div">
															Código de Autorización
														</Typography>
														<Typography variant="body2">
															{authorizationDetails.authorizationData.authorizationCode}
														</Typography>
													</CardContent>
												</Card>
											</Grid>
											<Grid item md={4} xs={12}>
												<Card sx={{ minWidth: 275 }}>
													<CardContent>
														<Typography variant="h5" component="div">
															Fecha de Cobro
														</Typography>
														<Typography variant="body2">
															{formatDateToShowUser(authorizationDetails.authorizationData.paymentDate)}
														</Typography>
													</CardContent>
												</Card>
											</Grid>
											<Grid item md={4} xs={12}>
												<Card sx={{ minWidth: 275 }}>
													<CardContent>
														<Typography variant="h5" component="div">
															Fecha de Vto. de Cobro
														</Typography>
														<Typography variant="body2">
															{formatDateToShowUser(authorizationDetails.authorizationData.paymentDueDate)}
														</Typography>
													</CardContent>
												</Card>
											</Grid>
											<Grid item md={4} xs={12}>
												<Card sx={{ minWidth: 275 }}>
													<CardContent>
														<Typography variant="h5" component="div">
															Estado
														</Typography>
														<Typography variant="body2">
															{getAuthorizationStateName(authorizationDetails.authorizationData.state)}
														</Typography>
													</CardContent>
												</Card>
											</Grid>
											<Grid item md={4} xs={12}>
												<Card sx={{ minWidth: 275 }}>
													<CardContent>
														<Typography variant="h5" component="div">
															Fecha de Creación
														</Typography>
														<Typography variant="body2">
															{formatDateTimeToShowUser(authorizationDetails.authorizationData.createdAt)}
														</Typography>
													</CardContent>
												</Card>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								{authorizationDetails &&
									authorizationDetails.authorizationData.chargedAgainstOptionType ==
										ChargedAgainstOptionTypeEnum.VC && (
										<Grid container marginBottom={1}>
											<Grid container xs={12}>
												<Grid item xs={8}>
													<Typography variant="h5">Cruces</Typography>
												</Grid>
												<Grid item xs={4} display="flex" justifyContent={'flex-end'} gap={1}>
													<Button
														onClick={() => setCreateETicketModal(true)}
														variant="contained"
														endIcon={<ReceiptIcon />}
													>
														Agregar E-Ticket
													</Button>
													<Button
														color="warning"
														variant="contained"
														endIcon={<PaidIcon />}
														onClick={() => setCreateRefundModal(true)}
													>
														Desvincular Boleto
													</Button>
												</Grid>
											</Grid>
										</Grid>
									)}
								<TableContainer component="main" sx={{ maxWidth: 'xl', maxHeight: 500 }}>
									<div>
										<Table stickyHeader size="small">
											<TableHead>
												<TableRow>
													<TableCell className={classes.tableHeader} align="right">
														ID
													</TableCell>
													<TableCell className={classes.tableHeader} align="right">
														Validating Carrier
													</TableCell>
													<TableCell className={classes.tableHeader} align="center">
														Monto{' '}
													</TableCell>
													<TableCell className={classes.tableHeader} align="right">
														Fecha de Cruce
													</TableCell>
													<TableCell className={classes.tableHeader} align="right">
														E-Ticket
													</TableCell>
													<TableCell className={classes.tableHeader} align="right">
														Operador
													</TableCell>
													<TableCell className={classes.tableHeader} align="right">
														Estado
													</TableCell>
													<TableCell className={classes.tableHeader} align="right">
														Fecha de Creación
													</TableCell>
													<TableCell className={classes.tableHeader} align="right">
														Cruzado por
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{authorizationDetails?.crossesData.length ? (
													authorizationDetails?.crossesData.map((cross) => (
														<TableRow
															key={cross.id}
															hover
															sx={{
																'&:last-child td, &:last-child th': { border: 0 },
																'*': { color: cross.state === CrossStateEnum.UNBIND ? 'red' : '' },
															}}
														>
															<TableCell align="justify" component="p">
																{cross.id}
															</TableCell>
															<TableCell align="justify" component="p">
																{cross.validatingCarrierName}
															</TableCell>
															<TableCell align="center" component="p">
																{cross.amount} {cross.coinCode}
															</TableCell>
															<TableCell align="right" component="p">
																{formatDateToShowUser(cross.dateCross)}
															</TableCell>
															<TableCell align="right" component="p">
																{cross.eticket}
															</TableCell>
															<TableCell align="right" component="p">
																{getCrossOperatorName(operators, cross.operator)}
															</TableCell>
															<TableCell align="right" component="p">
																{getCrossStateName(cross.state)}
															</TableCell>
															<TableCell align="right" component="p">
																{formatDateTimeToShowUser(cross.createdAt)}{' '}
															</TableCell>
															<TableCell align="right" component="p">
																{cross.createdBy}
															</TableCell>
														</TableRow>
													))
												) : (
													<TableRow>
														<TableCell align="center" colSpan={10}>
															No hay Cruces que mostrar aún
														</TableCell>
													</TableRow>
												)}
											</TableBody>
										</Table>
									</div>
								</TableContainer>
							</Grid>
						</Grid>
					</>
					<CreateUnbindModal
						reloadList={getDetails}
						authorizationId={authorizationDetails?.authorizationData?.id}
						eTickets={refundableETickets}
						operators={operators}
						open={createRefundModal}
						handleClose={() => setCreateRefundModal(false)}
					/>
					<CreateETicketModal
						reloadList={getDetails}
						authorizationId={authorizationDetails?.authorizationData?.id}
						open={createETicketModal}
						handleClose={() => setCreateETicketModal(false)}
					/>
				</>
			)}
			<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};

import React from 'react';
import { Layout } from 'features/common/components/Layout';
import { Card, CardContent, CardMedia, Typography, Grid, Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { isOperatorOrHigher, isAdminOrSuperAdmin } from '../../features/auth/helpers';
import { CountryCodeEnum } from '../../features/common/types';

const cardData = [
	{
		id: 1,
		icon: '📝',
		title: 'Crear coti',
		description: 'Crear una nueva cotización a partir de un deal',
		link: '/quotations/new',
		isExternal: false,
		allowedCountries: [
			CountryCodeEnum.URUGUAY,
			CountryCodeEnum.PARAGUAY,
			CountryCodeEnum.BOLIVIA,
			CountryCodeEnum.COLOMBIA,
			CountryCodeEnum.TN_ITAU_PY,
		],
		visibleTo: 'all',
	},
	{
		id: 2,
		icon: '🛒',
		title: 'Crear orden',
		description: 'Crear una nueva orden de venta a partir de un deal',
		link: '/salesorder/new',
		isExternal: false,
		allowedCountries: [
			CountryCodeEnum.URUGUAY,
			CountryCodeEnum.PARAGUAY,
			CountryCodeEnum.BOLIVIA,
			CountryCodeEnum.COLOMBIA,
			CountryCodeEnum.TN_ITAU_PY,
		],
		visibleTo: 'all',
	},
	{
		id: 3,
		icon: '🏝️',
		title: 'Paquetes',
		description: 'Ver paquetes disponibles y crear una coti a partir del mismo',
		link: '/stats/packages',
		isExternal: false,
		allowedCountries: [
			CountryCodeEnum.URUGUAY,
			CountryCodeEnum.PARAGUAY,
			CountryCodeEnum.BOLIVIA,
			CountryCodeEnum.COLOMBIA,
			CountryCodeEnum.TN_ITAU_PY,
		],
		visibleTo: 'all',
	},
	{
		id: 9,
		icon: '💳',
		title: 'Cobrar c/tarjeta',
		description: (
			<>
				Enviar un link de pago de <strong>aerolinea</strong>.
			</>
		),
		link: '/payments/order/1',
		isExternal: false,
		allowedCountries: [CountryCodeEnum.URUGUAY],
		visibleTo: 'all',
	},
	{
		id: 4,
		icon: '💳',
		title: 'Cobrar c/tarjeta',
		description: (
			<>
				Enviar un link de pago de <strong>pasarela</strong>.
			</>
		),
		link: '/payments/order/2',
		isExternal: false,
		allowedCountries: [CountryCodeEnum.URUGUAY],
		visibleTo: 'all',
	},
	{
		id: 10,
		icon: '💳',
		title: 'Cobrar c/tarjeta',
		description: 'Enviar un link de pago de pasarela.',
		link: '/payments/order/3',
		isExternal: false,
		allowedCountries: [CountryCodeEnum.PARAGUAY],
		visibleTo: 'all',
	},
	{
		id: 11,
		icon: '💳',
		title: 'Cobrar c/tarjeta',
		description: 'Enviar un link de pago de pasarela.',
		link: '/payments/order/5',
		isExternal: false,
		allowedCountries: [CountryCodeEnum.COLOMBIA],
		visibleTo: 'all',
	},
	{
		id: 12,
		icon: '💳',
		title: 'Cobrar c/tarjeta',
		description: 'Enviar un link de pago de pasarela.',
		link: '/payments/order/4',
		isExternal: false,
		allowedCountries: [CountryCodeEnum.BOLIVIA],
		visibleTo: 'all',
	},
	{
		id: 13,
		icon: '💳',
		title: 'Cobrar c/tarjeta',
		description: 'Enviar un link de pago de pasarela.',
		link: '/payments/viaje-naranja-order',
		isExternal: false,
		allowedCountries: [CountryCodeEnum.TN_ITAU_PY],
		visibleTo: 'all',
	},
	{
		id: 5,
		icon: '💵',
		title: 'Cobrar c/efectivo',
		description: 'Registrar el cobro en efectivo.',
		link: '/payments/order-cash',
		isExternal: false,
		allowedCountries: [
			CountryCodeEnum.URUGUAY,
			CountryCodeEnum.PARAGUAY,
			CountryCodeEnum.BOLIVIA,
			CountryCodeEnum.COLOMBIA,
		],
		visibleTo: 'all',
	},
	{
		id: 6,
		icon: '🏦',
		title: 'Cobrar c/trf',
		description: 'Validar una transferencia bancaria.',
		link: '/payments/wiretransfers/check',
		isExternal: false,
		allowedCountries: [
			CountryCodeEnum.URUGUAY,
			CountryCodeEnum.PARAGUAY,
			CountryCodeEnum.BOLIVIA,
			CountryCodeEnum.COLOMBIA,
			CountryCodeEnum.TN_ITAU_PY,
		],
		visibleTo: 'all',
	},
	{
		id: 7,
		icon: '✈️',
		title: 'Operaciones Aereas',
		description: 'Solicitudes al equipo de operaciones aereas',
		link: '/operaciones-aereas',
		isExternal: false,
		allowedCountries: [
			CountryCodeEnum.URUGUAY,
			CountryCodeEnum.PARAGUAY,
			CountryCodeEnum.BOLIVIA,
			CountryCodeEnum.COLOMBIA,
			CountryCodeEnum.TN_ITAU_PY,
		],
		visibleTo: 'all',
	},
	{
		id: 10,
		icon: '💰',
		title: 'Cuentas bancarias',
		description: 'Información de las cuentas bancarias habilitadas para realizar cobros',
		link: 'https://cuentas.mevuelo.com/uy',
		isExternal: true,
		allowedCountries: [CountryCodeEnum.URUGUAY],
		visibleTo: 'all',
	},
	{
		id: 11,
		icon: '💰',
		title: 'Cuentas bancarias',
		description: 'Información de las cuentas bancarias habilitadas para realizar cobros',
		link: 'https://cuentas.mevuelo.com/py',
		isExternal: true,
		allowedCountries: [CountryCodeEnum.PARAGUAY],
		visibleTo: 'all',
	},
	{
		id: 12,
		icon: '💰',
		title: 'Cuentas bancarias',
		description: 'Información de las cuentas bancarias habilitadas para realizar cobros',
		link: 'https://cuentas.mevuelo.com/bo',
		isExternal: true,
		allowedCountries: [CountryCodeEnum.BOLIVIA],
		visibleTo: 'all',
	},
	{
		id: 13,
		icon: '💰',
		title: 'Cuentas bancarias',
		description: 'Información de las cuentas bancarias habilitadas para realizar cobros',
		link: 'https://cuentas.mevuelo.com/co',
		isExternal: true,
		allowedCountries: [CountryCodeEnum.COLOMBIA],
		visibleTo: 'all',
	},
	{
		id: 8,
		icon: '↩️',
		title: 'Devoluciones',
		description: 'Solicitar una devolucion para un cliente',
		link: '/devoluciones',
		isExternal: false,
		allowedCountries: [
			CountryCodeEnum.URUGUAY,
			CountryCodeEnum.PARAGUAY,
			CountryCodeEnum.BOLIVIA,
			CountryCodeEnum.COLOMBIA,
			CountryCodeEnum.TN_ITAU_PY,
		],
		visibleTo: 'all',
	},
	{
		id: 14,
		icon: '📊',
		title: 'Reporting',
		description: 'Acceder al reporte de Looker Studio para ver el estado de mis ventas',
		link: '/report',
		isExternal: false,
		allowedCountries: [
			CountryCodeEnum.URUGUAY,
			CountryCodeEnum.PARAGUAY,
			CountryCodeEnum.BOLIVIA,
			CountryCodeEnum.COLOMBIA,
			CountryCodeEnum.TN_ITAU_PY,
		],
		visibleTo: 'all',
	},
	{
		id: 15,
		icon: '💳',
		title: 'Pago de servicio',
		description: 'Conciliación de pago directo con tarjeta de crédito corporativa',
		link: 'https://forms.gle/JSpyosUzdbv1ySfB6',
		isExternal: true,
		allowedCountries: [
			CountryCodeEnum.URUGUAY,
			CountryCodeEnum.PARAGUAY,
			CountryCodeEnum.BOLIVIA,
			CountryCodeEnum.COLOMBIA,
			CountryCodeEnum.TN_ITAU_PY,
		],
		visibleTo: 'operator',
	},
	{
		id: 16,
		icon: '🤝🏼',
		title: 'Nimbux CRM',
		description: 'Nuevo CRM y sistema de tickets',
		link: 'https://crm.nimbux.tech/',
		isExternal: true,
		allowedCountries: [
			CountryCodeEnum.URUGUAY,
			CountryCodeEnum.PARAGUAY,
			CountryCodeEnum.BOLIVIA,
			CountryCodeEnum.COLOMBIA,
			CountryCodeEnum.TN_ITAU_PY,
		],
		visibleTo: 'operator',
	},
];

const Home = (): JSX.Element => {
	const { auth } = useSelector((state: RootState) => state);

	const filteredCards = cardData.filter(
		(card) =>
			card.allowedCountries.includes(auth.country) &&
			(card.visibleTo === 'all' ||
				(card.visibleTo === 'operator' && isOperatorOrHigher(auth.user)) ||
				(card.visibleTo === 'admin' && isAdminOrSuperAdmin(auth.user))),
	);
	return (
		<Layout>
			<>
				<Box sx={{ flexGrow: 1, padding: 2, margin: '0 20%' }}>
					<Grid container spacing={2} justifyContent="center">
						{filteredCards.map((card) => (
							<Grid item xs={12} sm={6} md={4} lg={3} key={card.id}>
								{card.isExternal ? (
									<a href={card.link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
										<Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', maxWidth: '240' }}>
											<CardMedia
												component="div"
												sx={{ padding: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
											>
												<Typography variant="h4">{card.icon}</Typography>
											</CardMedia>
											<CardContent sx={{ flexGrow: 1 }}>
												<Typography variant="subtitle1" component="div">
													{card.title}
												</Typography>
												<Typography variant="body2" color="text.secondary">
													{card.description}
												</Typography>
											</CardContent>
										</Card>
									</a>
								) : (
									<RouterLink to={card.link} style={{ textDecoration: 'none' }}>
										<Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', maxWidth: '240' }}>
											<CardMedia
												component="div"
												sx={{ padding: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
											>
												<Typography variant="h4">{card.icon}</Typography>
											</CardMedia>
											<CardContent sx={{ flexGrow: 1 }}>
												<Typography variant="subtitle1" component="div">
													{card.title}
												</Typography>
												<Typography variant="body2" color="text.secondary">
													{card.description}
												</Typography>
											</CardContent>
										</Card>
									</RouterLink>
								)}
							</Grid>
						))}
					</Grid>
				</Box>
			</>
		</Layout>
	);
};

export default Home;

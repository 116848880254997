import React, { useState, useEffect, useCallback } from 'react';
import { Controller, useFieldArray, useForm, FormProvider } from 'react-hook-form';
import {
	Grid,
	Button,
	TextField,
	Typography,
	Autocomplete,
	Box,
	Switch,
	FormControlLabel,
	CircularProgress,
} from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import LockIcon from '@mui/icons-material/Lock';
import KingBedIcon from '@mui/icons-material/KingBed';
import DeleteIcon from '@mui/icons-material/Delete';

import { useStyles } from 'features/salesOrder/components/SalesOrderServiceForm/components/InsuranceServiceForm/style';

import { checkShowErrorMessage, nanoidGenerator } from 'features/common/helpers';
import { ControlledTextField } from 'features/common/components/ControlledTextField';
import { ControlledSelect } from 'features/common/components/ControlledSelect';
import { ControlledAutoComplete } from 'features/common/components/ControlledAutoComplete';
import { ControlledDatePicker } from 'features/common/components/ControlledDatePicker';
import {
	typeUnit,
	accommodationFeeType,
	accommodationRoomBoard,
	ICodeName,
	ServiceUnitEnum,
	IServiceCostVariationLogs,
	IAccommodationService,
	TypeServiceEnum,
} from 'features/salesOrder/types';
import {
	calcularPrecioFinal,
	calculatMarkupPercentaje,
	excludeTypeUnits,
	filterServiceCostVariationLogs,
} from 'features/salesOrder/components/SalesOrderServiceForm/helpers';
import { DestinationSuggestion } from 'features/quotation/types';
import { destinationSuggestion } from 'services';
import { AutocompleteProviders } from 'features/common/components/AutocompleteProviders';
import { ServiceCostVariationLogs } from 'features/salesOrder/components/SalesOrderServiceForm/components/ServiceCostVariationLogs';
import {
	getAccomodationProviderId,
	getCostVariationLogsByService,
	postSendAccomodationServiceVoucher,
	putServiceForm,
} from 'features/salesOrder/services';
import { useParams } from 'react-router-dom';
import {
	AccommodationServiceProviderTypeEnum,
	AutocompleteAccomodationProviders,
} from 'features/common/components/AutocompleteAccomodationProviders';
import { ShowAlertState } from 'features/common/types';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { getRates } from 'features/stats/services';

interface Props {
	index: number;
	passengers: { keyId: string; name: string; lastName: string }[];
	service: IAccommodationService;
	currencyesList: ICodeName[];
	onUpdateService: () => void;
	onSetAlert: (values: ShowAlertState) => void;
	onSetLoading: (value: boolean) => void;
	isExistingService?: boolean;
}
interface RoomVouchers {
	id: string;
	url: string;
}
const iconMapping = {
	Grupo: <GroupIcon />,
	Habitacion: <KingBedIcon />,
};

const format = (date: string) => {
	return date;
};

export const AccommodationService = ({
	index,
	passengers,
	service,
	currencyesList,
	onUpdateService,
	onSetAlert,
	onSetLoading,
	isExistingService,
}: Props): JSX.Element => {
	const classes = useStyles();
	const methods = useForm();
	const {
		control,
		formState: { errors },
		setValue,
		getValues,
		watch,
	} = methods;
	const [roomVouchers, setRoomVouchers] = useState<RoomVouchers[]>([]);
	const { fields, append, remove } = useFieldArray({
		control,
		// @ts-ignore
		name: 'rooms',
	});

	const { auth } = useSelector((state: RootState) => state);
	const theCountry = auth.country;

	const [serviceCostVariationLogs, setServiceCostVariationLogs] = useState<IServiceCostVariationLogs[]>([]);

	const { id: saleOrderId } = useParams<{ id: string }>();
	const [searchCity, setSearchCity] = useState<string>('');
	const [searchOptions, setSearchOptions] = useState<DestinationSuggestion[]>([]);
	const [rerender, setRerender] = useState(false);
	const [tariffProviderId, setTariffProviderId] = useState('n/a');
	const [accommodationServiceProviderType, setAccommodationServiceProviderType] =
		useState<AccommodationServiceProviderTypeEnum>(AccommodationServiceProviderTypeEnum.Hotelbeds);
	const idService = service.keyId;

	const filteredArray = filterServiceCostVariationLogs(serviceCostVariationLogs, idService);

	const loadCostLog = useCallback(async () => {
		const result = await getCostVariationLogsByService(saleOrderId, idService);
		setServiceCostVariationLogs(result.data);
	}, [setServiceCostVariationLogs]);

	const handleCostChange = (newCost: number) => {
		// @ts-ignore
		setValue('totalCost', newCost, { shouldDirty: true });
		const saleAmount = getValues('totalSaleAmount' as `${string}.${string | number}`) || 0;
		const newMarkup = calculatMarkupPercentaje(newCost, saleAmount) as string;
		// @ts-ignore
		setValue('markupPercentage', newMarkup, { shouldDirty: true });
	};
	const handleMarkupPercentageChange = (newValue: number) => {
		// @ts-ignore
		setValue('markupPercentage', newValue, { shouldDirty: true });
		const cost = getValues('totalCost' as `${string}.${string | number}`) || 0;
		const newSaleAmount = calcularPrecioFinal(cost, newValue) as string;
		// @ts-ignore
		setValue('totalSaleAmount', newSaleAmount, { shouldDirty: true });
	};

	const handleSaleAmountChange = (newValue: number) => {
		// @ts-ignore
		setValue('totalSaleAmount', newValue, { shouldDirty: true });
		const cost = getValues('totalCost' as `${string}.${string | number}`) || 0;

		if (newValue === 0) {
			setValue('markupPercentage', 0, { shouldDirty: true });
		} else {
			const newMarkup = calculatMarkupPercentaje(cost, newValue) as string;

			// @ts-ignore
			setValue('markupPercentage', newMarkup, { shouldDirty: true });
		}
	};

	const handleRoomCostChange = (cost: number, roomIndex: number) => {
		// @ts-ignore
		setValue(`rooms.${roomIndex}.cost`, cost, { shouldDirty: true });
		const saleAmount = getValues(`rooms.${roomIndex}.saleAmount` as `${string}.${string | number}`) || 0;
		// @ts-ignore
		if (saleAmount === '0') {
			// @ts-ignore
			setValue(`rooms.${roomIndex}.markupPercentage`, 0, { shouldDirty: true });
		} else {
			const newMarkup = calculatMarkupPercentaje(cost, saleAmount) as string;
			// @ts-ignore
			setValue(`rooms.${roomIndex}.markupPercentage`, newMarkup, { shouldDirty: true });
		}
		calculateTotalCost();
	};

	const handleRoomMarkupPercentageChange = (newValue: number, roomIndex: number) => {
		// @ts-ignore
		setValue(`rooms.${roomIndex}.markupPercentage`, newValue, { shouldDirty: true });
		const cost = getValues(`rooms.${roomIndex}.cost` as `${string}.${string | number}`) || 0;
		const newSaleAmount = calcularPrecioFinal(cost, newValue);
		// @ts-ignore
		setValue(`rooms.${roomIndex}.saleAmount`, newSaleAmount, { shouldDirty: true });

		calculateTotalCost();
	};

	const handleRoomSaleAmountChange = (newValue: number, roomIndex: number) => {
		// @ts-ignore
		setValue(`rooms.${roomIndex}.saleAmount`, newValue, { shouldDirty: true });
		const cost = getValues(`rooms.${roomIndex}.cost` as `${string}.${string | number}`) || 0;
		if (newValue === 0) {
			setValue(`rooms.${roomIndex}.markupPercentage` as `${string}.${string | number}`, 0);
		} else {
			const newMarkup = calculatMarkupPercentaje(cost, newValue);

			// @ts-ignore
			setValue(`rooms.${roomIndex}.markupPercentage`, newMarkup, { shouldDirty: true });
		}
	};

	const sameTariffServiceProvider = watch('sameTariffServiceProvider' as `${string}.${string | number}`, false);

	const costGroup = watch('cost' as `${string}.${string | number}`) || 0;
	const saleAmountGroup = watch('saleAmount' as `${string}.${string | number}`) || 0;
	const unit = watch('unit' as `${string}.${string | number}`);

	const calculateTotalCost = useCallback(() => {
		let keepWhile = true;
		let roomIndex = 0;
		let totalCostRooms = 0;
		let totalSaleAmountRooms = 0;
		if (unit === 'PerRoom') {
			while (keepWhile) {
				const costForCurrentRoom =
					// @ts-ignore
					getValues(`rooms.${roomIndex}.cost`);
				const saleAmountCurrentRoom =
					// @ts-ignore
					getValues(`rooms.${roomIndex}.saleAmount`);

				if (costForCurrentRoom) {
					totalCostRooms += Number(costForCurrentRoom) * 1;
					totalSaleAmountRooms += Number(saleAmountCurrentRoom) * 1;
					roomIndex++;
				} else {
					keepWhile = false;
				}
			}
			setValue('totalCost' as `${string}.${string | number}`, totalCostRooms, { shouldDirty: true });
			setValue('totalSaleAmount' as `${string}.${string | number}`, totalSaleAmountRooms, {
				shouldDirty: true,
			});
			if (theCountry === 'CO') {
				const rateValue = getValues('currencyRate' as `${string}.${string | number}`) || 1;
				const totalEnColombianos = totalSaleAmountRooms * rateValue;
				setValue('totalSaleAmountInLocalCurrency', Number(totalEnColombianos.toFixed(2)));
			}
		} else {
			// @ts-ignore
			const cost = Number(getValues('totalCost'));
			// @ts-ignore
			const saleAmount = Number(getValues('totalSaleAmount'));
			const markup = calculatMarkupPercentaje(cost, saleAmount);
			setValue('markupPercentage' as `${string}.${string | number}`, Number(markup), { shouldDirty: true });
			if (theCountry === 'CO') {
				const rateValue = getValues('currencyRate' as `${string}.${string | number}`) || 1;
				const totalEnColombianos = saleAmount * rateValue;
				setValue('totalSaleAmountInLocalCurrency', Number(totalEnColombianos.toFixed(2)));
			}
		}
	}, [costGroup, saleAmountGroup, unit]);

	useEffect(() => {
		if (sameTariffServiceProvider) {
			setValue('serviceProviderId' as `${string}.${string | number}`, tariffProviderId);
		}
		if (!sameTariffServiceProvider) {
			setValue('sameTariffServiceProvider' as `${string}.${string | number}`, false);
		}
	}, [sameTariffServiceProvider, tariffProviderId]);

	const addRooms = () => {
		const newRoom = {
			keyId: nanoidGenerator(),
			passengerIds: [],
			roomType: '',
			category: '',
			tariffType: 'n/a',
			board: '',
		};
		append(newRoom);
	};
	const handleDelete = (roomIndex: number) => {
		const currentRooms = getValues('rooms' as `${string}.${string | number}`) || [];

		if (roomIndex >= 0 && roomIndex < currentRooms.length) {
			const updatedRooms = currentRooms.filter((_: any, i: number) => i !== roomIndex);
			remove(roomIndex);
			setValue('rooms' as `${string}.${string | number}`, updatedRooms);
		}
	};

	useEffect(() => {
		const fetchApi = async () => {
			const data = await destinationSuggestion(searchCity);
			data && data.data && setSearchOptions(data.data);
		};
		if (searchCity && searchCity.length >= 3) fetchApi();
	}, [searchCity]);

	const handleChangeCity = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchCity(e.target.value);
	};

	useEffect(() => {
		const limitDateField = document.querySelector('input[name="limitDate"]');
		const startDate = document.querySelector('input[name="startDate"]');
		const endDate = document.querySelector('input[name="endDate"]');
		if (limitDateField) {
			// @ts-ignore
			limitDateField.value = '';
		}
		if (startDate) {
			// @ts-ignore
			startDate.value = '';
		}
		if (endDate) {
			// @ts-ignore
			endDate.value = '';
		}

		const effectTariffProviderId =
			typeof service.tariffProvider === 'string'
				? service.tariffProvider || 'n/a'
				: service.tariffProvider?.id || 'n/a';
		setTariffProviderId(effectTariffProviderId);
		const effectServiceProviderId = service.serviceProvider?.id || 'n/a';
		setValue('reference' as `${string}.${string | number}`, service.reference || '', { shouldDirty: true });
		// setValue('tariffProvider' as `${string}.${string | number}`, effectTariffProviderId, {
		// 	shouldDirty: true,
		// });
		setValue('tariffProviderId' as `${string}.${string | number}`, effectTariffProviderId);
		setValue(
			'unit' as `${string}.${string | number}`,
			service.unit.length === 0 ? ServiceUnitEnum.PerRoom : service.unit,
			{ shouldDirty: true },
		);
		setValue('totalCost' as `${string}.${string | number}`, service.totalCost || 0, { shouldDirty: true });
		setValue('markupPercentage' as `${string}.${string | number}`, service.markupPercentage || 0, {
			shouldDirty: true,
		});
		setValue('totalSaleAmount' as `${string}.${string | number}`, service.totalSaleAmount || 0, {
			shouldDirty: true,
		});
		setValue('keyId' as `${string}.${string | number}`, service.keyId);
		setValue('currency' as `${string}.${string | number}`, service.currency || 'USD', { shouldDirty: true });
		setValue('voucherNotes' as `${string}.${string | number}`, service.voucherNotes || '', {
			shouldDirty: true,
		});
		setValue('internalNotes' as `${string}.${string | number}`, service.internalNotes || '', {
			shouldDirty: true,
		});
		setValue('serviceProviderId' as `${string}.${string | number}`, effectServiceProviderId, {
			shouldDirty: true,
		});
		setAccommodationServiceProviderType(
			service.serviceProvider?.providerType || AccommodationServiceProviderTypeEnum.Hotelbeds,
		);
		setValue('sameTariffServiceProvider' as `${string}.${string | number}`, service.sameTariffServiceProvider || false);
		setValue('destination' as `${string}.${string | number}`, service.destination || '', {
			shouldDirty: true,
		});
		setValue('startDate' as `${string}.${string | number}`, service.startDate || '', { shouldDirty: true });
		setValue('endDate' as `${string}.${string | number}`, service.endDate || '', { shouldDirty: true });
		setValue('limitDate' as `${string}.${string | number}`, service.limitDate || '', { shouldDirty: true });
		const roomsToSet = (service.rooms || []).map((room: any) => ({
			...room,
			markupPercentage: room.markupPercentage || 0,
		}));
		setValue('rooms' as `${string}.${string | number}`, roomsToSet);
		if (service.rooms?.length > 0 && fields.length < 1) {
			service.rooms.forEach((room: any) => {
				const checkIfExists = service.rooms.find((roomB) => roomB.keyId === room.keyId);
				!checkIfExists && append(room);
			});
		}

		if (auth.country === 'CO') {
			if (!isExistingService) {
				const fetchRates = async () => {
					const result = await getRates();
					setValue('currencyRate', result.data.ratesList[0].values[0].fee);
				};
				fetchRates();
			} else {
				setValue('currencyRate', service.currencyRate || 1);
			}
			setValue('totalSaleAmountInLocalCurrency', service.totalSaleAmountInLocalCurrency || 1);
		}

		setRerender((prev) => !prev);
		service.cost && handleCostChange(Number(service.cost));
		service.saleAmount && handleSaleAmountChange(Number(service.saleAmount));
	}, [setValue, index, service, auth, isExistingService]);

	useEffect(() => {
		if (fields.length < 1 && service.rooms === undefined) {
			addRooms();
		}
	}, [fields, service, auth]);

	useEffect(() => {
		setValue('currency' as `${string}.${string | number}`, 'USD', { shouldDirty: true });
	}, [setValue]);

	calculateTotalCost();

	const handleVoucher = async () => {
		// Estar activo
		if (!service.enable) {
			alert('El servicio no está activo, no se puede enviar el voucher');
			return;
		}

		// Tener una referencia válida (no puede ser vacía ni '-')
		if (!service.reference || service.reference === '-') {
			alert('Referencia (localizador) no válida, no se puede enviar el voucher');
			return;
		}

		// Tener proveedor de tarifa asociado
		if (!service.tariffProvider) {
			alert('Proveedor de tarifa inválido, no se puede enviar el voucher');
			return;
		}

		// Tener proveedor de servicio asociado
		if (!service.serviceProvider) {
			alert('Proveedor de servicio inválido, no se puede enviar el voucher');
			return;
		}

		// Tener habitaciones y pasajeros asociados.
		if (service.rooms.length < 1) {
			alert('Necesitamos rooms, no se puede enviar el voucher');
			return;
		} else {
			for (let i = 0; i < service.rooms.length; i++) {
				if (service.rooms[i].passengerIds.length < 1) {
					alert('A alguna room le faltan pasajeros, no se puede enviar el voucher');
					return;
				}
			}
		}

		// Proceso de envío del voucher
		setLoading(true);
		try {
			const response = await postSendAccomodationServiceVoucher(saleOrderId, idService, sendWhatsApp);
			console.log('Voucher enviado:', response.data);
			setRoomVouchers(response.data);
			onSetAlert({
				show: true,
				severity: 'success',
				message: 'Voucher enviado con éxito!',
			});
		} catch (error) {
			setLoading(false);
			// @ts-ignore
			alert(error.response.data.message);
		}
	};

	const handleUpdateService = async (data: any) => {
		try {
			onSetLoading(true);
			const serviceDTO = data as IAccommodationService;

			// @ts-ignore
			if (serviceDTO.startDate > serviceDTO.endDate) {
				throw 'Alojamiento, fecha final no puede ser mayor a la inicial';
			}

			serviceDTO.startDate = format(serviceDTO.startDate);
			serviceDTO.endDate = format(serviceDTO.endDate);
			serviceDTO.limitDate = format(serviceDTO.limitDate);
			// @ts-ignore
			serviceDTO.totalCost = Number(serviceDTO.totalCost);
			// @ts-ignore
			serviceDTO.totalSaleAmount = Number(serviceDTO.totalSaleAmount);

			if (serviceDTO.tariffProvider === 'n/a') {
				delete serviceDTO.tariffProvider;
				serviceDTO.sameTariffServiceProvider = false;
			} else if (typeof serviceDTO.tariffProvider === 'string') {
				// @ts-ignore
				serviceDTO.tariffProviderId = serviceDTO.tariffProvider;
				delete serviceDTO.tariffProvider;
			}

			// @ts-ignore
			if (serviceDTO.serviceProvider === 'n/a') {
				serviceDTO.sameTariffServiceProvider = false;
				// @ts-ignore
				delete serviceDTO.serviceProvider;
			}
			// @ts-ignore
			if (serviceDTO.serviceProviderId === 'n/a') {
				serviceDTO.sameTariffServiceProvider = false;
				// @ts-ignore
				delete serviceDTO.serviceProviderId;
			} else {
				try {
					// @ts-ignore
					const result = await getAccomodationProviderId(
						// @ts-ignore
						serviceDTO.serviceProviderId,
						accommodationServiceProviderType,
					);
					// @ts-ignore
					serviceDTO.serviceProvider = result.data;

					// @ts-ignore
					delete serviceDTO.serviceProviderId;
				} catch (error) {
					// @ts-ignore
					alert(error.response.data.message);
				} finally {
					onSetLoading(false);
				}
			}

			// @ts-ignore
			if (serviceDTO.tariffProviderId === 'n/a') {
				// @ts-ignore
				delete serviceDTO.tariffProviderId;
				serviceDTO.sameTariffServiceProvider = false;
			}
			if (serviceDTO.rooms) {
				serviceDTO.rooms.forEach((room) => {
					room.keyId = nanoidGenerator();
				});
			}

			// @ts-ignore
			if (typeof serviceDTO.unit === 'string' && serviceDTO.unit === ServiceUnitEnum.PerRoom) {
				let totalSaleAmount = 0;
				serviceDTO.rooms.forEach((room) => {
					totalSaleAmount = totalSaleAmount + Number(room?.saleAmount);
				});
				// @ts-ignore
				serviceDTO.totalSaleAmount = totalSaleAmount;

				delete serviceDTO.cost;
				delete serviceDTO.saleAmount;
			} else {
				// NO ENTIENDO PQ PUSE ESTE CODE LA VEZ PASADA
				// let _cost = 0;
				// let _saleAmount = 0;

				// for (let roomIndex = 0; roomIndex < serviceDTO.rooms.length; roomIndex++) {
				// 	_cost = _cost + Number(serviceDTO.rooms[roomIndex].cost || 0);
				// 	_saleAmount = _saleAmount + Number(serviceDTO.rooms[roomIndex].saleAmount || 0);
				// }

				// @ts-ignore
				serviceDTO.cost = serviceDTO.totalCost;
				// @ts-ignore
				serviceDTO.saleAmount = serviceDTO.totalSaleAmount;
			}
			if (Array.isArray(serviceDTO.unit) && serviceDTO.unit.includes(ServiceUnitEnum.Group) && serviceDTO.rooms) {
				serviceDTO.rooms.forEach((room) => {
					delete room.cost;
					delete room.markupPercentage;
					delete room.saleAmount;
				});
			}

			if (theCountry !== 'CO') {
				// @ts-ignore
				delete serviceDTO.currencyRate;
				// @ts-ignore
				delete serviceDTO.totalSaleAmountInLocalCurrency;
			}

			// This validates that a passenger is does not appear in two different
			// rooms.

			const checkedPassegners: string[] = [];
			let dupes = 0;
			for (let roomIndex = 0; roomIndex < serviceDTO.rooms.length; roomIndex++) {
				for (let paxIndex = 0; paxIndex < serviceDTO.rooms[roomIndex].passengerIds.length; paxIndex++) {
					const thisPaxId = serviceDTO.rooms[roomIndex].passengerIds[paxIndex];
					// if thisPaxId has not been checked
					if (!checkedPassegners.includes(thisPaxId)) {
						// check if paxId exists in some other room
						for (let roomCheckIndex = 0; roomCheckIndex < serviceDTO.rooms.length; roomCheckIndex++) {
							const checkRoom = serviceDTO.rooms[roomCheckIndex];
							if (checkRoom.passengerIds.includes(thisPaxId) && roomIndex !== roomCheckIndex) {
								dupes++;
							}
						}
					}
				}
			}
			if (dupes > 0) {
				throw 'Por cada sevicio de alohamiento, el pasajero solo puede peterencer a una habitacion';
			}

			serviceDTO.rooms?.forEach((room) => {
				room.cost = serviceDTO.unit.includes(ServiceUnitEnum.Group) ? undefined : room.cost;
				room.markupPercentage = serviceDTO.unit.includes(ServiceUnitEnum.Group) ? undefined : room.markupPercentage;
				room.saleAmount = serviceDTO.unit.includes(ServiceUnitEnum.Group) ? undefined : room.saleAmount;
			});

			serviceDTO.serviceType = TypeServiceEnum.Accommodation;

			// @ts-ignore
			if (serviceDTO.totalCost == 0 && serviceDTO.totalSaleAmount == 0) {
				// @ts-ignore
				delete serviceDTO.markupPercentage;
			} else {
				if (serviceDTO.unit === ServiceUnitEnum.PerRoom) {
					// @ts-ignore
					serviceDTO.markupPercentage = calculatMarkupPercentaje(serviceDTO.totalCost, serviceDTO.totalSaleAmount);
					if (isFinite(Number(serviceDTO.markupPercentage))) {
						// @ts-ignore
						serviceDTO.markupPercentage = Number(serviceDTO.markupPercentage);
					} else {
						// @ts-ignore
						serviceDTO.markupPercentage = 0;
					}
				}
			}

			await putServiceForm({ saleOrderId, serviceId: serviceDTO.keyId, data: serviceDTO });

			onSetAlert({
				show: true,
				severity: 'success',
				message: 'Alojamiento guardado con exito!',
			});

			onUpdateService();
		} catch (error) {
			// @ts-ignore
			alert(error.response.data.message);
		} finally {
			onSetLoading(false);
		}
	};

	useEffect(() => {
		if (unit === ServiceUnitEnum.Group) {
			for (let roomIndex = 0; roomIndex < fields.length; roomIndex++) {
				// @ts-ignore
				setValue(`rooms.${roomIndex}.cost`, 1);
				// @ts-ignore
				setValue(`rooms.${roomIndex}.saleAmount`, 0);
				// @ts-ignore
				setValue(`rooms.${roomIndex}.markupPercentage`, 0);
			}
		}
	}, [unit, fields, setValue]);

	const [sendWhatsApp, setSendWhatsApp] = useState(true); // Estado inicial encendido
	const [loading, setLoading] = useState(false);
	const handleToggleWhatsApp = (event: { target: { checked: boolean | ((prevState: boolean) => boolean) } }) => {
		setSendWhatsApp(event.target.checked);
	};

	useEffect(() => {
		loadCostLog();
	}, [loadCostLog, service]);

	return (
		<FormProvider {...methods}>
			<form
				onSubmit={methods.handleSubmit((data) => {
					handleUpdateService(data as IAccommodationService);
				})}
			>
				<Typography variant="h6">Descripción</Typography>
				<Box display="flex" flexDirection="column" gap={2}>
					<Box>
						<Typography color={'Grey'}>Referencia</Typography>
						<ControlledTextField
							name={'reference'}
							rules={{
								required: { value: true, message: 'Este campo es requerido' },
							}}
							helperText={checkShowErrorMessage(Boolean(errors.reference), errors.reference?.message)}
						/>
					</Box>
					<Box>
						<Typography color={'Grey'}>Destino</Typography>
						<Controller
							// @ts-ignore
							name={'destination' as const}
							control={control}
							rules={{ required: { value: true, message: 'Este campo es requerido' } }}
							render={({ field }) => (
								<Autocomplete
									options={searchOptions}
									getOptionLabel={(option) => (option ? option.name : '')}
									value={field.value}
									onChange={(event, newValue) => field.onChange(newValue)}
									renderInput={(params) => (
										<TextField
											{...params}
											className={classes.textField}
											size="small"
											onChange={handleChangeCity}
											fullWidth
											style={{ marginTop: -3 }}
										/>
									)}
									key={rerender.toString()}
								/>
							)}
						/>
					</Box>

					<Box display="flex" alignItems="end">
						<Box>
							<Typography color={'Grey'}>Proveedor de tarifas</Typography>
							<AutocompleteProviders
								name={'tariffProviderId'}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
							/>
						</Box>
					</Box>

					<Box>
						<Typography color={'Grey'}>Prestador de servicio</Typography>
						<AutocompleteAccomodationProviders
							allowNotApplicable
							name={'serviceProviderId'}
							rules={{
								required: { value: true, message: 'Este campo es requerido' },
							}}
							accommodationServiceProviderType={accommodationServiceProviderType}
							setAccommodationServiceProviderType={setAccommodationServiceProviderType}
						/>
					</Box>

					<Grid container spacing={2}>
						<Grid item xs={12} sm={4}>
							<Typography color={'Grey'}>Fecha inicial</Typography>
							<ControlledDatePicker
								name={'startDate'}
								rules={{ required: 'Este campo es requerido' }}
								inputFormat="dd/MM/yyyy"
								required={true}
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Typography color={'Grey'}>Fecha final</Typography>
							<ControlledDatePicker
								name={'endDate'}
								rules={{ required: 'Este campo es requerido' }}
								inputFormat="dd/MM/yyyy"
								required={true}
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Typography color={'Grey'}>Fecha limite</Typography>
							<ControlledDatePicker
								name={'limitDate'}
								rules={{ required: 'Este campo es requerido' }}
								inputFormat="dd/MM/yyyy"
								required={true}
							/>
						</Grid>
					</Grid>

					{fields.map((_room, roomIndex) => (
						<React.Fragment key={`rooms-${roomIndex}`}>
							<Grid container spacing={2} key={roomIndex}>
								<Grid item xs={12} sm={6}>
									<Typography mt={4} variant="h6">
										Habitación {roomIndex + 1}
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Typography color={'Grey'}>Voucher:</Typography>
									{roomVouchers[roomIndex] ? (
										<a href={roomVouchers[roomIndex].url} target="_blank" rel="noopener noreferrer">
											Abrir Voucher
										</a>
									) : (
										<Typography color={'Grey'}>Voucher no disponible</Typography>
									)}
								</Grid>
								<Grid item xs={12} sm={6}>
									{roomIndex !== 0 && (
										<Button
											className={classes.buttonDeleter}
											variant="outlined"
											onClick={() => handleDelete(roomIndex)}
										>
											<DeleteIcon />
											Eliminar habitacion
										</Button>
									)}
								</Grid>
								<Grid item xs={12} sm={12}>
									<Typography color={'Grey'} mb={1}>
										Pasajeros
									</Typography>
									<ControlledAutoComplete
										name={`rooms.${roomIndex}.passengerIds`}
										rules={{
											required: { value: true, message: 'Este campo es requerido' },
										}}
										options={passengers}
										isMultiple={true}
									/>
								</Grid>
								<Grid item xs={12} sm={4}>
									<Typography color={'Grey'}>Tipo de tarifa</Typography>
									<ControlledSelect
										name={`rooms.${roomIndex}.tariffType`}
										rules={{
											required: { value: true, message: 'Este campo es requerido' },
										}}
										options={accommodationFeeType.map((item) => ({ id: item.id.toString(), name: item.name }))}
									/>
								</Grid>
								<Grid item xs={12} sm={4}>
									<Typography color={'Grey'}>Habitacion</Typography>
									<ControlledTextField
										name={`rooms.${roomIndex}.room`}
										rules={{
											required: { value: true, message: 'Este campo es requerido' },
										}}
										helperText={checkShowErrorMessage(
											Boolean(errors.rooms?.[roomIndex]?.category),
											errors.rooms?.[roomIndex]?.category?.message,
										)}
									/>
								</Grid>
								<Grid item xs={12} sm={4}>
									<Typography color={'Grey'}>Comidas</Typography>
									<ControlledSelect
										name={`rooms.${roomIndex}.board`}
										rules={{
											required: { value: true, message: 'Este campo es requerido' },
										}}
										options={accommodationRoomBoard.map((item) => ({ id: item.id.toString(), name: item.name }))}
									/>
								</Grid>
								{unit != ServiceUnitEnum.Group && (
									<>
										<Grid item xs={12} sm={4}>
											<Typography color={'Grey'}>Costo</Typography>
											<ControlledTextField
												isNumber
												name={`rooms.${roomIndex}.cost`}
												rules={{
													required: { value: true, message: 'Este campo es requerido' },
												}}
												onChange={(value) => handleRoomCostChange(parseFloat(value), roomIndex)}
												helperText={checkShowErrorMessage(
													Boolean(errors.rooms?.[roomIndex]?.cost),
													errors.rooms?.[roomIndex]?.cost?.message,
												)}
											/>
										</Grid>
										<Grid item xs={12} sm={4}>
											<Typography color={'Grey'}>Markup %</Typography>
											<ControlledTextField
												isNumber
												name={`rooms.${roomIndex}.markupPercentage`}
												rules={{
													required: { value: true, message: 'Este campo es requerido' },
													pattern: {
														value: /^-?\d*\.?\d+$/,
														message: 'El valor debe ser numérico',
													},
												}}
												onChange={(value) => handleRoomMarkupPercentageChange(parseFloat(value), roomIndex)}
												helperText={checkShowErrorMessage(
													Boolean(errors.rooms?.[roomIndex]?.markupPercentage),
													errors.rooms?.[roomIndex]?.markupPercentage?.message,
												)}
											/>
										</Grid>
										<Grid item xs={12} sm={4}>
											<Typography color={'Grey'}>Venta</Typography>
											<ControlledTextField
												isNumber
												name={`rooms.${roomIndex}.saleAmount`}
												rules={{
													required: { value: true, message: 'Este campo es requerido' },
													pattern: {
														value: /^(0|[1-9]\d*)(\.\d+)?$/,
														message: 'El valor debe ser numérico y mayor o igual a 0',
													},
												}}
												onChange={(value) => handleRoomSaleAmountChange(parseFloat(value), roomIndex)}
												helperText={checkShowErrorMessage(
													Boolean(errors.rooms?.[roomIndex]?.saleAmount),
													errors.rooms?.[roomIndex]?.saleAmount?.message,
												)}
											/>
										</Grid>
									</>
								)}
							</Grid>
						</React.Fragment>
					))}
					<Box>
						<Button className={classes.buttonAdd} variant="outlined" onClick={addRooms}>
							+ Agregar Habitacion
						</Button>
					</Box>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={4}>
							<Typography color={'Grey'}>Unidad por</Typography>
							<ControlledSelect
								name={'unit'}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								options={excludeTypeUnits(typeUnit, ['Vehiculo', 'Camarote', 'Persona'])}
								iconTypeTrip={iconMapping}
							/>
						</Grid>
						<Grid item xs={12} sm={2}>
							<Typography color={'Grey'}>Moneda</Typography>
							<ControlledSelect
								name={'currency'}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								options={currencyesList.map((item) => ({ id: item.code.toString(), name: item.code }))}
							/>
						</Grid>
					</Grid>
					{unit === ServiceUnitEnum.Group ? (
						<Grid container spacing={2}>
							<Grid item xs={12} sm={2}>
								<Typography color={'Grey'}>Costo</Typography>
								<ControlledTextField
									name={'totalCost'}
									isNumber
									rules={{
										required: { value: true, message: 'Este campo es requerido' },
									}}
									onChange={(value) => handleCostChange(parseFloat(value))}
									helperText={checkShowErrorMessage(Boolean(errors.cost), errors.cost?.message)}
								/>
							</Grid>
							<Grid item xs={12} sm={2}>
								<Typography color={'Grey'}>Markup %</Typography>
								<ControlledTextField
									isNumber
									name={'markupPercentage'}
									rules={{
										required: { value: true, message: 'Este campo es requerido' },
										pattern: {
											value: /^-?\d*\.?\d+$/,
											message: 'El valor debe ser numérico',
										},
									}}
									onChange={(value) => handleMarkupPercentageChange(parseFloat(value))}
									helperText={checkShowErrorMessage(Boolean(errors.markupPercentage), errors.markupPercentage?.message)}
									disabled={unit !== ServiceUnitEnum.Group}
								/>
							</Grid>
							<Grid item xs={12} sm={3}>
								<Typography color={'Grey'}>Venta</Typography>
								<ControlledTextField
									isNumber
									name={'totalSaleAmount'}
									rules={{
										required: { value: true, message: 'Este campo es requerido' },
										pattern: {
											value: /^(0|[1-9]\d*)(\.\d+)?$/,
											message: 'El valor debe ser numérico y mayor o igual a 0',
										},
									}}
									onChange={(value) => handleSaleAmountChange(parseFloat(value))}
									helperText={checkShowErrorMessage(Boolean(errors.saleAmount), errors.saleAmount?.message)}
								/>
							</Grid>
							{theCountry === 'CO' && (
								<>
									<Grid item xs={2} sm={2}>
										<Typography color={'Grey'}>Tipo de Cambio</Typography>
										<ControlledTextField name={'currencyRate'} disabled={isExistingService} />
									</Grid>
									<Grid item xs={10} sm={2}>
										<Typography color={'Grey'}>Total en COP</Typography>
										<ControlledTextField name={'totalSaleAmountInLocalCurrency'} disabled />
									</Grid>
								</>
							)}
						</Grid>
					) : (
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6}>
								<Typography color={'Grey'}>Costo Total</Typography>
								<ControlledTextField name={'totalCost'} disabled />
							</Grid>
							<Grid item xs={12} sm={6}>
								<Typography color={'Grey'}>Venta Total</Typography>
								<ControlledTextField name={'totalSaleAmount'} disabled />
							</Grid>
							{theCountry === 'CO' && (
								<>
									<Grid item xs={0} sm={8}></Grid>
									<Grid item xs={2} sm={2}>
										<Typography color={'Grey'}>Tipo de Cambio</Typography>
										<ControlledTextField
											name={'currencyRate'}
											disabled={isExistingService}
											onChange={calculateTotalCost}
										/>
									</Grid>
									<Grid item xs={10} sm={2}>
										<Typography color={'Grey'}>Total en COP</Typography>
										<ControlledTextField name={'totalSaleAmountInLocalCurrency'} disabled />
									</Grid>
								</>
							)}
						</Grid>
					)}

					<Box>
						<Typography color={'Grey'}>Notas de voucher</Typography>
						<Controller
							// @ts-ignore
							name={'voucherNotes'}
							control={control}
							defaultValue="Favor de presentar voucher en el check-in"
							render={({ field }) => (
								<TextField
									fullWidth
									sx={{ backgroundColor: '#FFFFFF' }}
									variant="outlined"
									multiline
									rows={5}
									{...field}
									helperText={checkShowErrorMessage(Boolean(errors.voucherNotes), errors.voucherNotes?.message)}
								/>
							)}
						/>
					</Box>
					<Box>
						<Typography color={'Grey'}>
							<LockIcon /> Notas internas
						</Typography>
						<Controller
							// @ts-ignore
							name={'internalNotes'}
							control={control}
							render={({ field }) => (
								<TextField
									fullWidth
									sx={{ backgroundColor: '#FFFFFF' }}
									variant="outlined"
									multiline
									rows={5}
									{...field}
									helperText={checkShowErrorMessage(Boolean(errors.internalNotes), errors.internalNotes?.message)}
								/>
							)}
						/>
					</Box>
					<ServiceCostVariationLogs filteredArray={filteredArray} />
				</Box>
				{(service.enable || service.enable === undefined) && (
					<Box mt={2}>
						<Typography align="right" color={'Grey'}>
							***Guardar antes de generar vouchers***
						</Typography>
						<Typography align="right" color={'Grey'}>
							***Abrir sesion de whats app via crm antes de enviar***
						</Typography>
						<Box display="flex" gap={2} flexDirection="row-reverse" mt={2}>
							<FormControlLabel
								control={<Switch checked={sendWhatsApp} onChange={handleToggleWhatsApp} color="primary" />}
								label="Enviar vouchers x WhatsApp"
								labelPlacement="start"
							/>
							<Button type="button" onClick={handleVoucher} variant="contained" color="primary" disabled={loading}>
								{loading ? <CircularProgress size={24} color="inherit" /> : 'Generar vouchers'}
							</Button>
							<Button type="submit" variant="contained" color="success">
								Guardar
							</Button>
						</Box>
					</Box>
				)}
			</form>
		</FormProvider>
	);
};

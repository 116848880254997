import React, { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
	Box,
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
	Snackbar,
	Alert,
	Button,
	Divider,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { IChannel, ISaleOrderCreateForm, SegmentEnum, segmentTypes } from '../../types';
import { checkShowErrorMessage, nanoidGenerator } from 'features/common/helpers';
import { findContactDataByDeal } from 'services';
import { AutocompleteContactData, QuotationGeneralInfo } from 'features/quotation/types';
import { getQuotation, getQuotationOnlyFlights } from 'features/quotation/services';

import { ShowAlertState } from '../../../common/types';
import { useSelector } from 'react-redux';
import { getCountriesWithAgencyConfigForUser } from '../../../auth/helpers';
import { RootState } from '../../../../store';
import { createSalesOrder, getChannels } from '../../services';
import StoreIcon from '@mui/icons-material/Store';
import { extractErrorMessage } from '../../../quotation/helpers';
import BitrixDealOrCaseSwitch from '../../../common/components/BitrixDealOrCaseSwitch';
import { getNimbuxCaseSummary } from '../../../common/services';

interface IMessageInfoVerifyQuotation {
	message: string[] | string;
	success: boolean;
}

export const SalesOrderCreateForm = (): JSX.Element => {
	const history = useHistory();
	const { auth } = useSelector((state: RootState) => state);
	const {
		control,
		formState: { errors },
		getValues,
		setValue,
		reset,
		watch,
	} = useForm({
		mode: 'all',
		defaultValues: { segmentType: '', channelId: '', quotationId: '', dealId: '', clientReference: '' },
	});
	const [loading, setLoading] = useState<boolean>(false);
	const [channels, setChannels] = useState<IChannel[]>([]);
	const [contactDealCheck, setContactDealCheck] = useState<IMessageInfoVerifyQuotation | null>(null);
	const [quotation, setQuotation] = useState<IMessageInfoVerifyQuotation | null>(null);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = React.useState<ShowAlertState>(defaultAlertState);
	const [existQuotationId, setExistQuotationId] = useState(false);
	const quotationIdValue = watch('quotationId');
	const dealIdIdValue = watch('dealId');
	const [isBitrixDeal, setIsBitrixDeal] = useState<boolean>(true);
	const countriesConfigToRender = getCountriesWithAgencyConfigForUser(auth.user?.agencies.map((x) => x.agencyId) || []);

	const convertContactDataToMessage = (contact: AutocompleteContactData): string[] => {
		const stringMessageChunk = [];
		stringMessageChunk.push(`Nombre: ${contact.name}`);
		if (contact.email) stringMessageChunk.push(`Correo: ${contact.email}`);
		if (contact.phone) stringMessageChunk.push(`Teléfono: ${contact.phone}`);
		return stringMessageChunk;
	};
	const convertQuotationIdDataMessage = (contact: QuotationGeneralInfo): string[] => {
		const stringMessageChunk = [];
		stringMessageChunk.push(`Nombre: ${contact.name}`);
		stringMessageChunk.push(`crmTicket: ${contact.crmTicket}`);
		if (contact.email) stringMessageChunk.push(`Correo: ${contact.email}`);
		if (contact.phone) stringMessageChunk.push(`Teléfono: ${contact.phone}`);
		return stringMessageChunk;
	};
	//obtengo los datos de Quotation Id
	const quotationIdData = async () => {
		const quotationID = getValues('quotationId');
		if (quotationID) {
			try {
				setLoading(true);
				const response = await getQuotation(+quotationID);
				setQuotation({ message: convertQuotationIdDataMessage(response.data), success: true });
				setExistQuotationId(true);
				setLoading(false);
			} catch (error: any) {
				try {
					const responseOnlyFlights = await getQuotationOnlyFlights(+quotationID);
					setQuotation({
						message: convertQuotationIdDataMessage(responseOnlyFlights.data),
						success: true,
					});
					setExistQuotationId(true);
					setLoading(false);
				} catch (erro: any) {
					setQuotation({ message: 'No se encontro el quotation ID', success: false });
					setExistQuotationId(false);
					setLoading(false);
				}
			}
		}
	};
	const handleQuotationId = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			quotationIdData();
		}
	};
	//obtengo los datos del Deal ID
	const findContactOfDeal = async () => {
		const crmTicket = getValues('dealId');
		if (crmTicket) {
			try {
				setLoading(true);
				const response = await findContactDataByDeal(+crmTicket);
				setContactDealCheck({ message: convertContactDataToMessage(response.data), success: true });
				setLoading(false);
			} catch (error: any) {
				if (error.response.status === 404) {
					setContactDealCheck({ message: 'El deal no tiene contacto asociado', success: true });

					setLoading(false);
				} else {
					setContactDealCheck({ message: 'No existe un deal con ese ID', success: false });
					setLoading(false);
				}
			}
		}
	};

	const findNimbuxCaseSummary = async () => {
		const crmTicket = getValues('dealId');
		if (crmTicket) {
			try {
				setLoading(true);
				const response = await getNimbuxCaseSummary(+crmTicket);
				if (!response.data?.client) {
					setContactDealCheck({
						message: 'El caso no tiene cliente asociado, por favor asocie un cliente al caso',
						success: false,
					});
				} else {
					const client = response.data.client;
					const autocompleteContactData = {
						name: `${client.firstName ?? ''} ${client.lastName ?? ''}`.trim(),
						email: client.email,
						phone: client.completePhoneNumber,
					} as AutocompleteContactData;
					setContactDealCheck({ message: convertContactDataToMessage(autocompleteContactData), success: true });
				}

				setLoading(false);
			} catch (error: any) {
				const errorMessage = extractErrorMessage(error, 'Ocurrió un error al buscar el caso');
				setContactDealCheck({ message: errorMessage, success: false });
				setLoading(false);
			}
		}
	};
	const handleDealId = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			if (isBitrixDeal) {
				findContactOfDeal();
			} else {
				findNimbuxCaseSummary();
			}
		}
	};

	const onSubmit = async () => {
		try {
			setLoading(true);
			const values = getValues();
			const orderToSend: ISaleOrderCreateForm = {
				dealId: Number(values.dealId),
				isBitrixDeal: isBitrixDeal,
				quotationId: values.quotationId ? Number(values.quotationId) : undefined,
				segmentType: values.segmentType as SegmentEnum,
				channelId: Number(values.channelId),
				clientReference: values.clientReference != '' ? values.clientReference : undefined,
			};
			const id = nanoidGenerator();
			await createSalesOrder(id, orderToSend);
			setAlert({
				show: true,
				severity: 'success',
				message: '¡Orden generada correctamente!',
			});
			reset();
			setContactDealCheck(null);
			setQuotation(null);
			setExistQuotationId(false);
			setLoading(false);
			history.push(`/salesorder/${id}`);
		} catch (error: any) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al crear la orden'),
			});
			setLoading(false);
		}
	};

	const countryToRender = useMemo(
		() => countriesConfigToRender.find((countryConfig) => countryConfig.id === auth.country),
		[countriesConfigToRender, auth],
	);

	const isFormValid = () => {
		const isSegmentTypeValid = !errors.segmentType;
		const isDealIdValid = !errors.dealId;
		const isClientReferenceIdValid = !errors.clientReference;
		const isQuotationIdValid = !errors.quotationId;
		const isChannelIdValid = !errors.channelId;
		const isChannelIdSelected = !!getValues('channelId');
		const isSegmentTypeSelected = !!getValues('segmentType');
		return (
			isDealIdValid &&
			isSegmentTypeValid &&
			isSegmentTypeSelected &&
			isQuotationIdValid &&
			isChannelIdValid &&
			isChannelIdSelected &&
			isClientReferenceIdValid
		);
	};

	const loadChannels = async () => {
		setLoading(true);
		try {
			const response = await getChannels();
			setChannels(response.data);
		} catch (error: any) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al obtener los canales'),
			});
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		loadChannels();
	}, []);

	useEffect(() => {
		reset();
		setContactDealCheck(null);
		setQuotation(null);
		setExistQuotationId(false);
	}, [isBitrixDeal]);

	useEffect(() => {
		setValue('segmentType', '');
		setValue('channelId', '');
		setValue('quotationId', '');
		setValue('clientReference', '');
		setContactDealCheck(null);
		setQuotation(null);
		setExistQuotationId(false);
	}, [dealIdIdValue]);

	return (
		<>
			<form style={{ width: '600px' }}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
						<Grid style={{ display: 'flex' }}>
							<StoreIcon sx={{ color: 'Grey', marginTop: 2 }} />
							<p style={{ color: 'Grey', margin: 2, marginRight: 50, marginTop: 20 }}>Mercado</p>
						</Grid>
						<Box display="flex" alignItems="center" bgcolor="#FFFFFF" minWidth={200} borderRadius={2} sx={{ pl: 40 }}>
							<img
								src={countryToRender?.agencyIcon}
								alt={countryToRender?.countryName}
								style={{ width: '42px', margin: '4px' }}
							/>
							<span>{countryToRender?.countryName}</span>
						</Box>
					</Grid>
					<Divider orientation="horizontal" sx={{ my: 2, mx: 6, mb: 1 }} />
					<Grid item xs={12} sm={12}>
						<BitrixDealOrCaseSwitch isBitrixDeal={isBitrixDeal} onChange={() => setIsBitrixDeal(!isBitrixDeal)} />
					</Grid>
					<Grid item xs={12} sm={12}>
						<Controller
							name="dealId"
							control={control}
							rules={{
								required: { value: true, message: 'Este campo es requerido' },
								min: {
									value: 1,
									message: `El ${isBitrixDeal ? 'Deal ID' : 'Caso'} debe ser mayor a 0`,
								},
								pattern: {
									value: /^(0|[1-9]\d*)(\.\d+)?$/,
									message: 'El valor debe ser numérico y mayor que 0',
								},
							}}
							render={({ field }) => (
								<TextField
									variant="outlined"
									required={true}
									fullWidth
									type="number"
									label={isBitrixDeal ? 'Deal ID' : 'Caso'}
									onKeyPress={handleDealId}
									disabled={loading}
									helperText={checkShowErrorMessage(Boolean(errors.dealId), errors.dealId?.message)}
									error={Boolean(errors.dealId)}
									{...field}
									onChange={(event) => {
										field.onChange(event.target.value);
										if (contactDealCheck != null) {
											setContactDealCheck(null);
										}
									}}
								/>
							)}
						/>
						<p style={{ color: 'Grey', margin: 2 }}>
							(ingrese el {isBitrixDeal ? 'Deal ID' : 'Caso'} y luego oprima enter)
						</p>
						{contactDealCheck && (
							<Typography color={contactDealCheck.success ? 'green' : 'red'} fontSize={12}>
								{' '}
								{Array.isArray(contactDealCheck.message)
									? contactDealCheck.message.map((message) => {
											return <p key={message}>{message}</p>;
									  })
									: contactDealCheck.message}{' '}
							</Typography>
						)}
					</Grid>
					<Grid item xs={12} sm={12}>
						<Controller
							name="quotationId"
							control={control}
							rules={{
								required: false,
								min: {
									value: 1,
									message: 'Quotation id debe ser mayor a 0',
								},
								pattern: {
									value: /^(0|[1-9]\d*)(\.\d+)?$/,
									message: 'El valor debe ser numérico y mayor que 0',
								},
							}}
							render={({ field }) => (
								<TextField
									type="number"
									variant="outlined"
									fullWidth
									label="Quotation ID"
									onKeyPress={handleQuotationId}
									disabled={loading}
									helperText={checkShowErrorMessage(Boolean(errors.quotationId), errors.quotationId?.message)}
									error={Boolean(errors.quotationId)}
									{...field}
									onChange={(event) => {
										field.onChange(event.target.value);
										if (quotation != null) {
											setQuotation(null);
											setExistQuotationId(false);
										}
									}}
								/>
							)}
						/>
						<p style={{ color: 'Grey', margin: 2 }}>(ingrese un numero de Quotation ID y luego oprima enter)</p>
						{quotation && (
							<Typography color={quotation.success ? 'green' : 'red'} fontSize={12}>
								{' '}
								{Array.isArray(quotation.message)
									? quotation.message.map((message) => {
											return <p key={message}>{message}</p>;
									  })
									: quotation.message}{' '}
							</Typography>
						)}
					</Grid>
					<Grid item xs={12} sm={12}>
						<Controller
							name="segmentType"
							control={control}
							rules={{
								required: {
									value: true,
									message: 'seleccione segmento',
								},
							}}
							render={({ field, fieldState: { error } }) => (
								<FormControl sx={{ width: '100%', height: '100%' }}>
									<InputLabel>Segmento *</InputLabel>
									<Select
										labelId="segment"
										label="segmento"
										required={true}
										disabled={loading}
										sx={{ width: '100%' }}
										{...field}
									>
										{segmentTypes.map((segmentType) => {
											return (
												<MenuItem key={segmentType.id} value={segmentType.id}>
													{segmentType.name}
												</MenuItem>
											);
										})}
									</Select>
									{error && <FormHelperText sx={{ color: 'red' }}>{error.message}</FormHelperText>}
								</FormControl>
							)}
						/>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Controller
							name="channelId"
							control={control}
							rules={{
								required: {
									value: true,
									message: 'Seleccione un canal',
								},
							}}
							render={({ field, fieldState: { error } }) => (
								<FormControl sx={{ width: '100%', height: '100%' }}>
									<InputLabel>Canal *</InputLabel>
									<Select
										labelId="channelId"
										label="Canal"
										required={true}
										disabled={loading}
										sx={{ width: '100%' }}
										{...field}
									>
										{channels.map((channel) => {
											return (
												<MenuItem key={channel.id} value={channel.id}>
													{channel.name}
												</MenuItem>
											);
										})}
									</Select>
									{error && <FormHelperText sx={{ color: 'red' }}>{error.message}</FormHelperText>}
								</FormControl>
							)}
						/>
					</Grid>

					<Grid item xs={12} sm={12}>
						<Controller
							name="clientReference"
							control={control}
							rules={{
								required: false,
								maxLength: {
									value: 255,
									message: 'La referencia del cliente excede el largo máximo permitido de: 255 caracteres',
								},
							}}
							render={({ field }) => (
								<TextField
									type="text"
									variant="outlined"
									fullWidth
									label="Referencia de cliente"
									disabled={loading}
									helperText={checkShowErrorMessage(Boolean(errors.clientReference), errors.clientReference?.message)}
									error={Boolean(errors.clientReference)}
									{...field}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Button
							variant="contained"
							disabled={
								loading ||
								!isFormValid() ||
								!contactDealCheck ||
								(!existQuotationId && quotationIdValue != '') ||
								(contactDealCheck && !contactDealCheck.success)
							}
							color="success"
							fullWidth
							onClick={onSubmit}
						>
							Generar Orden
						</Button>
					</Grid>
				</Grid>
			</form>
			<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};

import React from 'react';
import { Layout } from 'features/common/components/Layout';
import { Card, CardContent, CardMedia, Typography, Grid, Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

const cardData = [
	{
		id: 1,
		icon: '🛠️',
		title: 'Errores Internos',
		example:
			'Se gestiona cuando ocurren errores dentro de la empresa, como falta de compra de maletas, traslados incorrectos, etc, el cliente pagó nuevamente por el servicio de forma directa y debemos retornarle el dinero.',
		needs: [
			'Deal/Caso',
			'Comprobante de pago del cliente',
			'Monto pago por el cliente',
			'Cuenta bancaria para realizar el reembolso',
			<>
				Demora aprox:<strong> 72 hrs</strong>
			</>,
		],
		link: 'https://crm.nimbux.tech/support/admin-devoluciones-errores-internos',
		isExternal: true,
		allowedCountries: ['UY', 'PY', 'BO', 'CO', 'TN'],
	},
	{
		id: 2,
		icon: '🔄',
		title: 'Errores de Terceros',
		example:
			'Se aplican en situaciones donde un proveedor ha fallado en proporcionar un servicio acordado, y se debe reclamar un reintegro.',
		needs: [
			'Deal/Caso',
			'Comprobante de pago del cliente',
			'Monto pago por el cliente',
			'Cuenta bancaria para realizar el reembolso',
			<>
				Demora aprox:<strong> 30 días</strong>
			</>,
		],
		link: 'https://crm.nimbux.tech/support/admin-devoluciones-errores-terceros',
		isExternal: true,
		allowedCountries: ['UY', 'PY', 'BO', 'CO', 'TN'],
	},
	{
		id: 3,
		icon: '✈️',
		title: 'Devoluciones Aéreas',
		example:
			'Este tipo de devolución se utiliza cuando los boletos deben ser devueltos de acuerdo a la política de la aerolínea o la devolución de tasas e impuestos.',
		needs: [
			'Deal/Caso',
			'El/los números de tickets a solicitar devolución',
			'Cuenta bancaria para realizar el reembolso',
			<>
				Demora aprox:<strong> 45 días</strong>
			</>,
		],
		link: 'https://crm.nimbux.tech/support/admin-devoluciones-aereos',
		isExternal: true,
		allowedCountries: ['UY', 'PY', 'BO', 'CO', 'TN'],
	},
	{
		id: 4,
		icon: '🏨',
		title: 'Devoluciones de Hotel',
		example: 'Estas devoluciones se realizan cuando se aplican políticas de cancelacion del alojamiento.',
		needs: [
			'Deal/Caso',
			'Localizador de la reserva',
			'Cuenta bancaria para realizar el reembolso',
			<>
				Demora aprox:<strong> 30 días</strong>
			</>,
		],
		link: 'https://crm.nimbux.tech/support/admin-devoluciones-alojamiento',
		isExternal: true,
		allowedCountries: ['UY', 'PY', 'BO', 'CO', 'TN'],
	},
	{
		id: 5,
		icon: '📋',
		title: 'Devoluciones de Servicios',
		example: 'Se procesan cuando hay problemas con los servicios prestados.',
		needs: [
			'Deal/Caso',
			'Localizador de la reserva',
			'Cuenta bancaria para realizar el reembolso',
			<>
				Demora aprox:<strong> 30 días</strong>
			</>,
		],
		link: 'https://crm.nimbux.tech/support/admin-devoluciones-otros-servicios',
		isExternal: true,
		allowedCountries: ['UY', 'PY', 'BO', 'CO', 'TN'],
	},
];

const Refund = (): JSX.Element => {
	const { auth } = useSelector((state: RootState) => state);

	const filteredCards = cardData.filter((card) => card.allowedCountries.includes(auth.country));

	return (
		<Layout>
			<>
				<Box sx={{ flexGrow: 1, padding: 2, margin: '0 15%' }}>
					<RouterLink to="/home" style={{ textDecoration: 'none' }}>
						<Typography mb={2}>Click para volver al HOME</Typography>
					</RouterLink>
					<Typography variant="h6">Devoluciones</Typography>
					<Typography variant="subtitle2">
						Hola! antes de avanzar con la solicitud de devolución para el cliente, recordá verificar cada una de las
						opciones disponibles, es muy importante comenzar el proceso correcto para evitar demoras. Si te quedan
						dudas, consulta con tu líder!
					</Typography>
					<Grid container paddingTop={4} spacing={2} justifyContent="space-evenly">
						{filteredCards.map((card) => (
							<Grid item xs={12} sm={6} md={4} lg={4} xl={2} key={card.id}>
								{card.isExternal ? (
									<a href={card.link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
										<Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', minWidth: '220px' }}>
											<CardMedia
												component="div"
												sx={{ padding: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
											>
												<Typography variant="h4">{card.icon}</Typography>
											</CardMedia>
											<CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
												<Typography variant="subtitle1" component="div" paddingBottom={2}>
													{card.title}
												</Typography>
												<div
													style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
												>
													<div style={{ flex: 1 }}>
														<Typography variant="body2" color="text.secondary">
															<strong>cuando se utiliza:</strong>
															<br />
															{card.example}
														</Typography>
													</div>
													<div style={{ flex: 1 }}>
														<Typography variant="body2" color="text.secondary">
															<strong>info necesaria:</strong>
															<br />
															{card.needs.map((need, idx) => (
																<span key={idx}>
																	👉 {need}
																	<br />
																</span>
															))}
														</Typography>
													</div>
												</div>
											</CardContent>
										</Card>
									</a>
								) : (
									<RouterLink to={card.link} style={{ textDecoration: 'none' }}>
										<Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', maxWidth: 240 }}>
											<CardMedia
												component="div"
												sx={{ padding: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
											>
												<Typography variant="h4">{card.icon}</Typography>
											</CardMedia>
											<CardContent sx={{ flexGrow: 1 }}>
												<Typography variant="subtitle1" component="div">
													{card.title}
												</Typography>
												<Typography variant="body2" color="text.secondary">
													{card.example}
												</Typography>
											</CardContent>
										</Card>
									</RouterLink>
								)}
							</Grid>
						))}
					</Grid>
				</Box>
				<Box sx={{ flexGrow: 1, padding: 2, margin: '0 15%' }}>
					<Card raised sx={{ display: 'flex', flexDirection: 'column', height: '100%', padding: 2 }}>
						<Typography variant="h6" color="error">
							IMPORTANTE
						</Typography>
						<Typography fontWeight="bold" variant="subtitle1">
							Sobre la cuenta bancaria del cliente
						</Typography>
						<Typography variant="body2">
							La informacion debe contener todos los datso citados a continuacion, de lo contrario podriamos
							experimentar errores o demoras:
						</Typography>
						<Typography variant="body2">- Banco</Typography>
						<Typography variant="body2">- Sucursal</Typography>
						<Typography variant="body2">- Titular de la cuenta</Typography>
						<Typography variant="body2">- Número de cuenta</Typography>
						<Typography variant="body2">- Tipo de cuenta (caja de ahorros, cuenta corriente)</Typography>
						<Typography variant="body2">- Moneda</Typography>
					</Card>
				</Box>
			</>
		</Layout>
	);
};

export default Refund;

// FlightFormConfig.tsx

import React, { useEffect, useState } from 'react';
import {
	Box,
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	Grid,
	InputLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	SelectChangeEvent,
	TextField,
} from '@mui/material';
import carriers from '../../../../constants/carriers';
import ImageUploader from '../../../common/components/ImageUploader/ImageUploader';
import makeStyles from '@mui/styles/makeStyles';
import { Air, BaggageTypes, QuotationFlightImage } from '../../types';
import { parseGDSAirRawData } from '../../../../services';
import { changeOrderBack, changeOrderForward, extractBase64FromFiles, reOrderFlightImages } from '../../helpers';
import { nanoidGenerator } from '../../../common/helpers';
import { IDefaultPriceConfig } from './AirConfigCompleteQuotation';
import FlightImageConfigList from './FlightImageConfigList';
import FlightConfigTypeSwitch, { ConfigType } from './FlightConfigTypeSwitch';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3),
	},
	formControl: {
		width: '100%', // Fix IE 11 issue.
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	previewTitle: {
		marginBottom: theme.spacing(3),
	},
	configureFlight: {
		float: 'right',
	},
}));

export interface FlightFormConfigProps {
	handleAddAir: (air: Air) => void;
	defaultPriceConfig?: IDefaultPriceConfig;
	initialData?: Air; // Datos iniciales para edición
	isEditing?: boolean; // Indica si es modo edición
}

export const FlightFormConfig: React.FC<FlightFormConfigProps> = ({
	defaultPriceConfig,
	handleAddAir,
	initialData,
	isEditing = false,
}: FlightFormConfigProps) => {
	const classes = useStyles();

	// Inicializar los campos de precio con defaultPriceConfig si no se está editando
	const [priceADT, setPriceADT] = useState<number>(
		isEditing && initialData ? initialData.fareADT : defaultPriceConfig?.adtPrice ?? 0,
	);
	const [priceCHD, setPriceCHD] = useState<number>(
		isEditing && initialData ? initialData.fareCHD : defaultPriceConfig?.chdPrice ?? 0,
	);
	const [priceINF, setPriceINF] = useState<number>(
		isEditing && initialData ? initialData.fareINF : defaultPriceConfig?.infPrice ?? 0,
	);

	const [validatingCarrier, setValidatingCarrier] = useState(initialData?.validatingCarrier || '');
	const [gdsRawData, setGdsRawData] = useState(initialData?.tst || '');
	const [flightImages, setFlightImages] = useState<QuotationFlightImage[]>(initialData?.images || []);
	const [baggageType, setBaggageType] = useState<string>(initialData?.baggage || BaggageTypes.NO_BAGGAGE);
	const [carryOn, setCarryOn] = useState<boolean>(initialData?.carryOn ?? true);
	const [configType, setConfigType] = useState<ConfigType>((initialData?.data?.length ?? 0) > 0 ? 'GDS' : 'images');

	// Agregar un useEffect para reiniciar el formulario cuando no se está editando
	useEffect(() => {
		console.log('useEffect called:', { isEditing, initialData });
		if (isEditing && initialData) {
			console.log('Editing flight:', initialData);
			setValidatingCarrier(initialData.validatingCarrier);
			setPriceADT(initialData.fareADT);
			setPriceCHD(initialData.fareCHD);
			setPriceINF(initialData.fareINF);
			setGdsRawData(initialData.tst);
			setFlightImages(initialData.images || []);
			setBaggageType(initialData.baggage || BaggageTypes.NO_BAGGAGE);
			setCarryOn(initialData.carryOn ?? true);
			setConfigType((initialData.data?.length ?? 0) > 0 ? 'GDS' : 'images');
		} else {
			console.log('Resetting form to defaults');
			resetFormAddFlight();
		}
	}, [initialData, isEditing, defaultPriceConfig]);

	const handleChange = () => {
		if (configType === 'GDS' && flightImages.length > 0) {
			setFlightImages([]);
		} else {
			setGdsRawData('');
		}
		setConfigType((prevConfig) => (prevConfig === 'GDS' ? 'images' : 'GDS'));
	};

	const handleCarrierChange = (event: SelectChangeEvent) => {
		setValidatingCarrier(event.target.value as string);
	};

	const handlePriceADTChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setPriceADT(Number(e.target.value));
	};

	const handlePriceCHDChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setPriceCHD(Number(e.target.value));
	};

	const handlePriceINFChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setPriceINF(Number(e.target.value));
	};

	const handleBaggageTypeChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
		console.log('Baggage Type changed to:', value);
		setBaggageType(value);
	};

	const handleGdsRawDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setGdsRawData(e.target.value);
	};

	const handleToggleCarryOn = () => {
		setCarryOn((prevState) => !prevState);
	};

	const resetFormAddFlight = () => {
		setValidatingCarrier('');
		setPriceADT(defaultPriceConfig?.adtPrice ?? 0);
		setPriceCHD(defaultPriceConfig?.chdPrice ?? 0);
		setPriceINF(defaultPriceConfig?.infPrice ?? 0);
		setGdsRawData('');
		setFlightImages([]);
		setBaggageType(BaggageTypes.NO_BAGGAGE); // Puedes cambiar esto si deseas un valor predeterminado diferente
		setCarryOn(true);
		setConfigType('GDS');
	};

	const isValidToAddFlight = () => {
		return !isEditing
			? validatingCarrier.trim() !== '' &&
					priceADT > 0 &&
					(configType === 'GDS' ? gdsRawData.trim() !== '' : flightImages.length > 0)
			: validatingCarrier.trim() !== '' &&
					priceADT > 0 &&
					(configType === 'GDS' ? Array.isArray(initialData?.data) : Array.isArray(initialData?.images));
	};

	const handleOnImagesUpload = async (imagesFiles: File[]) => {
		try {
			const imagesBase64 = await extractBase64FromFiles(imagesFiles);
			if (flightImages.length === 0) {
				setFlightImages(
					imagesBase64.map((imageBase64, index) => ({
						image: imageBase64,
						order: index + 1,
						id: nanoidGenerator(),
					})),
				);
			} else {
				setFlightImages([
					...flightImages,
					...imagesBase64.map((imageBase64, index) => ({
						image: imageBase64,
						order: index + 1 + flightImages.length,
						id: nanoidGenerator(),
					})),
				]);
			}
		} catch (e) {
			console.error(e);
		}
	};

	const handleOnRemoveImageFromForm = (id: string) => {
		const updatedImages = reOrderFlightImages(flightImages.filter((image) => image.id !== id));
		setFlightImages(updatedImages);
	};

	const handleOnMoveForwardInForm = (id: string) => {
		setFlightImages(changeOrderForward(id, flightImages));
	};

	const handleOnMoveBackInForm = (id: string) => {
		setFlightImages(changeOrderBack(id, flightImages));
	};

	const handleSubmit = async () => {
		let flightInfos = Array.isArray(initialData?.data) ? initialData?.data : [];
		if (configType === 'GDS' && gdsRawData) {
			const result = await parseGDSAirRawData(gdsRawData);
			flightInfos = result.data;
		}
		const toDispatch: Air = {
			fareADT: priceADT,
			fareCHD: priceCHD,
			fareINF: priceINF,
			validatingCarrier,
			baggage: baggageType,
			tst: gdsRawData,
			carryOn,
			data: flightInfos,
			images: flightImages,
		};
		handleAddAir(toDispatch);
		// Siempre reseteamos el formulario después de agregar o actualizar
		resetFormAddFlight();
	};

	// Log para verificar el estado actual de baggageType
	console.log('Current Baggage Type:', baggageType);

	return (
		<form className={classes.form} noValidate>
			<Grid container spacing={2}>
				{/* Selección de Carrier */}
				<Grid item xs={12} sm={6}>
					<FormControl variant="outlined" className={classes.formControl}>
						<InputLabel id="carrier-select-label">Carrier</InputLabel>
						<Select
							labelId="carrier-select-label"
							id="carrier"
							value={validatingCarrier}
							onChange={handleCarrierChange}
							label="Carrier"
						>
							<MenuItem value="">
								<em>None</em>
							</MenuItem>
							{carriers.map((item) => (
								<MenuItem key={item.code} value={item.code}>
									{item.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>

				{/* Campos de Precio */}
				<Grid item xs={12} sm={2}>
					<TextField
						variant="outlined"
						required
						fullWidth
						id="adtPrice"
						label="ADT Price"
						name="adtPrice"
						autoComplete="adtPrice"
						value={priceADT}
						onChange={handlePriceADTChange}
					/>
				</Grid>
				<Grid item xs={12} sm={2}>
					<TextField
						variant="outlined"
						required
						fullWidth
						id="chdPrice"
						label="CHD Price"
						name="chdPrice"
						type="number"
						autoComplete="chdPrice"
						value={priceCHD}
						onChange={handlePriceCHDChange}
					/>
				</Grid>
				<Grid item xs={12} sm={2}>
					<TextField
						variant="outlined"
						required
						fullWidth
						name="infPrice"
						label="INF Price"
						id="infPrice"
						autoComplete="infPrice"
						value={priceINF}
						onChange={handlePriceINFChange}
					/>
				</Grid>

				{/* Selección de Equipaje y Carry On */}
				<Grid item xs={12}>
					<FormControl component="fieldset">
						<Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center">
							<FormControlLabel
								control={<Checkbox checked={carryOn} onChange={handleToggleCarryOn} name="carryOn" color="primary" />}
								label="Incluye carry on"
								sx={{ mr: 2 }}
							/>
							<RadioGroup
								aria-label="baggageType"
								name="baggageType"
								value={baggageType}
								onChange={handleBaggageTypeChange}
								row
							>
								<FormControlLabel value={BaggageTypes.NO_BAGGAGE} control={<Radio />} label="Sin maleta en bodega" />
								<FormControlLabel value={BaggageTypes.ONE_X_23K} control={<Radio />} label="1 maleta de 23kg" />
								<FormControlLabel value={BaggageTypes.TWO_X_23K} control={<Radio />} label="2 maletas de 23kg" />
							</RadioGroup>
						</Box>
					</FormControl>
				</Grid>

				{/* Selector de Tipo de Configuración de Vuelo */}
				<Grid item xs={6}>
					<FlightConfigTypeSwitch configType={configType} onConfigChange={handleChange} />
				</Grid>

				{/* Campo de Datos RAW de GDS */}
				{configType === 'GDS' && (
					<Grid item xs={12}>
						<TextField
							id="gdsRawData"
							label="GDS Raw Data"
							multiline
							rows={8}
							variant="outlined"
							value={gdsRawData}
							onChange={handleGdsRawDataChange}
							fullWidth
						/>
					</Grid>
				)}

				{/* Subida y Gestión de Imágenes */}
				{configType === 'images' && (
					<Grid item xs={12}>
						<ImageUploader onImageUpload={handleOnImagesUpload}></ImageUploader>
						{flightImages.length > 0 && (
							<FlightImageConfigList
								images={flightImages}
								onRemove={handleOnRemoveImageFromForm}
								onMoveBackward={handleOnMoveBackInForm}
								onMoveForward={handleOnMoveForwardInForm}
							/>
						)}
					</Grid>
				)}
			</Grid>

			{/* Botón de Envío */}
			<Button
				type="button"
				fullWidth
				disabled={!isValidToAddFlight()}
				variant="contained"
				color="primary"
				className={classes.submit}
				onClick={handleSubmit}
			>
				{isEditing ? 'Actualizar Vuelo' : 'Agregar Opcion de Vuelo'}
			</Button>
		</form>
	);
};

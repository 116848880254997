import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useFieldArray, FormProvider, useForm } from 'react-hook-form';
import { Grid, TextField, Typography, Button, Box } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import RepeatIcon from '@mui/icons-material/Repeat';
import PersonIcon from '@mui/icons-material/Person';
import TimeToLeaveIcon from '@mui/icons-material/TimeToLeave';

import { useStyles } from 'features/salesOrder/components/SalesOrderServiceForm/components/InsuranceServiceForm/style';
import {
	typeTransfer,
	typeUnit,
	ICodeName,
	IServiceCostVariationLogs,
	ITransferService,
	TypeServiceEnum,
	TransferTypeEnum,
} from 'features/salesOrder/types';
import { checkShowErrorMessage } from 'features/common/helpers';
import { ControlledAutoComplete } from 'features/common/components/ControlledAutoComplete';
import { ControlledTextField } from 'features/common/components/ControlledTextField';
import { ControlledCheckBox } from 'features/common/components/ControlledCheckBox';
import { ControlledSelect } from 'features/common/components/ControlledSelect';
import { ControlledDatePicker } from 'features/common/components/ControlledDatePicker';
import { AutocompleteProviders } from 'features/common/components/AutocompleteProviders';
import {
	calcularPrecioFinal,
	calculatMarkupPercentaje,
	excludeTypeUnits,
	filterServiceCostVariationLogs,
} from 'features/salesOrder/components/SalesOrderServiceForm/helpers';
import { useParams } from 'react-router-dom';
import { getCostVariationLogsByService, putServiceForm } from 'features/salesOrder/services';
import { ShowAlertState } from 'features/common/types';
import { ServiceCostVariationLogs } from 'features/salesOrder/components/SalesOrderServiceForm/components/ServiceCostVariationLogs';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { getRates } from 'features/stats/services';

interface Props {
	index: number;
	passengers: { keyId: string; name: string; lastName: string }[];
	service: ITransferService;
	currencyesList: ICodeName[];
	onUpdateService: () => void;
	onSetAlert: (values: ShowAlertState) => void;
	onSetLoading: (value: boolean) => void;
	isExistingService?: boolean;
}
const iconMapping = {
	Persona: <PersonIcon />,
	Vehiculo: <TimeToLeaveIcon />,
};
const iconType = {
	Ida: <ArrowRightAltIcon />,
	'Ida y vuelta': <RepeatIcon />,
};

export const TransferService = ({
	index,
	passengers,
	service,
	currencyesList,
	onUpdateService,
	onSetAlert,
	onSetLoading,
	isExistingService,
}: Props): JSX.Element => {
	const classes = useStyles();
	const methods = useForm();
	const [tariffProviderId, setTariffProviderId] = useState('n/a');
	const { id: saleOrderId } = useParams<{ id: string }>();
	const {
		control,
		formState: { errors },
		getValues,
		setValue,
		watch,
	} = methods;
	const { fields, append, remove } = useFieldArray({
		control,
		// @ts-ignore
		name: 'transferSegments',
	});
	const idService = service.keyId;
	const [serviceCostVariationLogs, setServiceCostVariationLogs] = useState<IServiceCostVariationLogs[]>([]);
	const unit = watch('unit' as `${string}.${string | number}`);
	const saleAmount = watch('saleAmount' as `${string}.${string | number}`);

	const { auth } = useSelector((state: RootState) => state);
	const theCountry = auth.country;

	const filteredArray = filterServiceCostVariationLogs(serviceCostVariationLogs, idService);

	const loadCostLog = useCallback(async () => {
		const result = await getCostVariationLogsByService(saleOrderId, idService);
		setServiceCostVariationLogs(result.data);
	}, [setServiceCostVariationLogs]);

	const handleCostChange = (newCost: number) => {
		// @ts-ignore
		setValue('cost', newCost, { shouldDirty: true });
		const saleAmount = getValues('saleAmount' as `${string}.${string | number}`) || 0;
		const markupPercentage = calculatMarkupPercentaje(newCost, saleAmount) as string;
		// @ts-ignore
		setValue('markupPercentage', markupPercentage, { shouldDirty: true });
		calculateTotalCost();
	};

	const handleSaleAmountChange = (newValue: number) => {
		// @ts-ignore
		setValue('saleAmount', newValue, { shouldDirty: true });
		const cost = getValues('cost' as `${string}.${string | number}`) || 0;

		if (newValue === 0) {
			setValue('markupPercentage', 0);
		} else {
			const newMarkup = calculatMarkupPercentaje(cost, newValue) as string;

			// @ts-ignore
			setValue('markupPercentage', newMarkup, { shouldDirty: true });
		}
		calculateTotalCost();
	};

	const handleMarkupPercentageChange = (newValue: number) => {
		// @ts-ignore
		setValue('markupPercentage', newValue, { shouldDirty: true });
		const cost = getValues('cost' as `${string}.${string | number}`) || 0;
		const newSaleAmount = calcularPrecioFinal(cost, newValue) as string;
		// @ts-ignore
		setValue('saleAmount', newSaleAmount, { shouldDirty: true });
		calculateTotalCost();
	};

	const sameTariffServiceProvider = watch('sameTariffServiceProvider' as `${string}.${string | number}`, false);

	const handleCheckboxChange = () => {
		const updatedValue = !sameTariffServiceProvider;
		setValue('sameTariffServiceProvider' as `${string}.${string | number}`, updatedValue);
	};

	const passengersSelected = watch('passengerIds' as `${string}.${string | number}`) || [];

	const calculateTotalCost = useCallback(() => {
		let cost = getValues('cost' as `${string}.${string | number}`) || 0;
		let saleAmount = getValues('saleAmount' as `${string}.${string | number}`) || 0;

		if (unit === 'Person') {
			cost = cost * passengersSelected.length;
			saleAmount =
				saleAmount * passengersSelected.length || (saleAmount ? saleAmount * passengersSelected.length : null);
		}

		setValue('totalCost' as `${string}.${string | number}`, cost);
		setValue('totalSaleAmount' as `${string}.${string | number}`, saleAmount);

		if (theCountry === 'CO') {
			const rateValue = getValues('currencyRate' as `${string}.${string | number}`) || 1;
			const totalEnColombianos = saleAmount * rateValue;
			setValue('totalSaleAmountInLocalCurrency', Number(totalEnColombianos.toFixed(2)));
		}
	}, [getValues, passengersSelected, setValue, unit, auth]);

	useEffect(() => {
		if (unit === 'Person' && saleAmount !== null) {
			const newTotalSaleAmount = saleAmount * passengersSelected.length;
			setValue('totalSaleAmount' as `${string}.${string | number}`, newTotalSaleAmount);
		}
	}, [saleAmount, passengersSelected.length]);

	useEffect(() => {
		if (sameTariffServiceProvider) {
			setValue('serviceProviderId' as `${string}.${string | number}`, tariffProviderId);
		}
		if (!sameTariffServiceProvider) {
			setValue('sameTariffServiceProvider' as `${string}.${string | number}`, false);
		}
	}, [sameTariffServiceProvider, tariffProviderId]);

	const addstretches = () => {
		const newstretches = {
			transferType: '',
			originName: '',
			destinationName: '',
			startDate: '',
			endDate: '',
		};
		append(newstretches);
	};

	const handleDelete = (index: number) => {
		remove(index);
	};

	const handleUpdateService = async (data: ITransferService) => {
		try {
			onSetLoading(true);
			const transferService = data;
			if (transferService.transferSegments) {
				transferService.transferSegments = transferService.transferSegments.map((segment) => {
					const toReturn = { ...segment };

					if (
						// @ts-ignore
						segment.transferType === TransferTypeEnum.InOut ||
						// @ts-ignore
						segment.transferType === TransferTypeEnum.InOutPrivate
					) {
						toReturn.startDate = segment.startDate;
						toReturn.endDate = segment.endDate;
					} else {
						toReturn.startDate = segment.startDate;
						if (toReturn.endDate) {
							//@ts-ignore
							delete toReturn.endDate;
						}
					}
					return toReturn;
				});
			}
			// @ts-ignore
			if (transferService.serviceProviderId === 'n/a') {
				transferService.sameTariffServiceProvider = false;
				// @ts-ignore
				delete transferService.serviceProviderId;
			}
			// @ts-ignore
			if (transferService.tariffProviderId === 'n/a') {
				// @ts-ignore
				delete transferService.tariffProviderId;
			}

			transferService.serviceType = TypeServiceEnum.Transfer;

			if (transferService.totalSaleAmount === null) {
				// @ts-ignore
				transferService.totalSaleAmount = 0;
			}
			if (theCountry !== 'CO') {
				// @ts-ignore
				delete transferService.currencyRate;
				// @ts-ignore
				delete transferService.totalSaleAmountInLocalCurrency;
			}

			await putServiceForm({ saleOrderId, serviceId: transferService.keyId, data: transferService });
			onSetAlert({
				show: true,
				severity: 'success',
				message: 'Transfer guardado con exito!',
			});
			onUpdateService();
		} catch (error) {
			// @ts-ignore
			alert(error.response.data.message);
		} finally {
			onSetLoading(false);
		}
	};

	useEffect(() => {
		const effectTariffProviderId =
			typeof service.tariffProvider === 'string'
				? service.tariffProvider || 'n/a'
				: service.tariffProvider?.id || 'n/a';
		setTariffProviderId(effectTariffProviderId);
		const effectServiceProviderId = service.serviceProvider?.id || 'n/a';
		setValue('reference' as `${string}.${string | number}`, service.reference);
		setValue('tariffProviderId' as `${string}.${string | number}`, effectTariffProviderId);
		setValue('unit' as `${string}.${string | number}`, service.unit || '');
		setValue('cost' as `${string}.${string | number}`, service.cost || 0);
		setValue('saleAmount' as `${string}.${string | number}`, service.saleAmount || 0);
		setValue('currency' as `${string}.${string | number}`, service.currency || 0);
		setValue('keyId' as `${string}.${string | number}`, service.keyId);
		setValue('markupPercentage' as `${string}.${string | number}`, service.markupPercentage || 0);
		setValue('voucherNotes' as `${string}.${string | number}`, service.voucherNotes || '');
		setValue('internalNotes' as `${string}.${string | number}`, service.internalNotes || '');
		setValue('passengerIds' as `${string}.${string | number}`, service.passengerIds || '');
		setValue('serviceProviderId' as `${string}.${string | number}`, effectServiceProviderId);
		setValue('sameTariffServiceProvider' as `${string}.${string | number}`, service.sameTariffServiceProvider);
		setValue('transferSegments' as any, service.transferSegments || []);
		if (service.transferSegments && service.transferSegments?.length > 0) {
			service.transferSegments.forEach((segment: any) => {
				// originName: string;
				// destinationName: string;
				// startDate: string;
				// endDate: string;
				const checkIfExists = service.transferSegments?.find(
					(segmentB) =>
						segmentB.originName === segment.originName &&
						segmentB.destinationName === segment.destinationName &&
						segmentB.startDate === segment.startDate &&
						segmentB.endDate === segment.endDate,
				);
				!checkIfExists && append(segment);
			});
		}
		service.cost && handleCostChange(Number(service.cost));
		service.saleAmount && handleSaleAmountChange(Number(service.saleAmount));
		if (auth.country === 'CO') {
			if (!isExistingService) {
				const fetchRates = async () => {
					const result = await getRates();
					setValue('currencyRate', result.data.ratesList[0].values[0].fee);
				};
				fetchRates();
			} else {
				setValue('currencyRate', service.currencyRate || 1);
			}
			setValue('totalSaleAmountInLocalCurrency', service.totalSaleAmountInLocalCurrency || 1);
		}
	}, [setValue, index, append, service, auth, isExistingService]);

	useEffect(() => {
		if (fields.length < 1 && service.transferSegments === undefined) {
			addstretches();
		}
	}, [fields, service]);

	useEffect(() => {
		setValue('currency' as `${string}.${string | number}`, 'USD');
	}, [setValue]);

	useEffect(() => {
		loadCostLog();
	}, [loadCostLog, service]);

	calculateTotalCost();
	return (
		<FormProvider {...methods}>
			<form onSubmit={methods.handleSubmit((data) => handleUpdateService(data as ITransferService))}>
				<Typography variant="h6">Descripción</Typography>
				<Box display="flex" flexDirection="column" gap={2}>
					<Box>
						<Typography color={'Grey'} mb={1}>
							Pasajeros
						</Typography>
						<ControlledAutoComplete
							name={'passengerIds'}
							rules={{
								required: { value: true, message: 'Este campo es requerido' },
							}}
							options={passengers}
							isMultiple={true}
							helperText={checkShowErrorMessage(Boolean(errors.passengerIds), errors.passengerIds?.message)}
						/>
					</Box>
					<Grid item xs={12} sm={12}>
						<Typography color={'Grey'}>Referencia</Typography>
						<ControlledTextField
							name={'reference'}
							rules={{
								required: { value: true, message: 'Este campo es requerido' },
							}}
							helperText={checkShowErrorMessage(Boolean(errors.reference), errors.reference?.message)}
						/>
					</Grid>
					<Box display="flex">
						<Box display="flex" alignItems="center">
							<Box>
								<Typography color={'Grey'}>Proveedor de tarifas</Typography>
								<AutocompleteProviders
									name={'tariffProviderId'}
									rules={{
										required: { value: true, message: 'Este campo es requerido' },
									}}
								/>
							</Box>
							<ControlledCheckBox
								name={'sameTariffServiceProvider'}
								label="Mismo proveedor de tarifa/servicio"
								onChange={handleCheckboxChange}
							/>
						</Box>

						{!sameTariffServiceProvider && (
							<Grid item xs={12} sm={6}>
								<Typography color={'Grey'}>Prestador de servicio</Typography>
								<AutocompleteProviders
									name={'serviceProviderId'}
									rules={{
										required: { value: true, message: 'Este campo es requerido' },
									}}
								/>
							</Grid>
						)}
					</Box>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={4}>
							<Typography color={'Grey'}>Unidad por</Typography>
							<ControlledSelect
								name={'unit'}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								options={excludeTypeUnits(typeUnit, ['Habitacion', 'Grupo', 'Camarote'])}
								iconTypeTrip={iconMapping}
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Typography color={'Grey'}>Moneda</Typography>
							<ControlledSelect
								name={'currency'}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								options={currencyesList.map((item) => ({ id: item.code.toString(), name: item.code }))}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={4}>
							<Typography color={'Grey'}>Costo</Typography>
							<ControlledTextField
								name={'cost'}
								isNumber
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
									min: {
										value: 0,
										message: 'El costo debe ser mayor a -1',
									},
								}}
								onChange={(value) => handleCostChange(parseFloat(value))}
								helperText={checkShowErrorMessage(Boolean(errors.cost), errors.cost?.message)}
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Typography color={'Grey'}>Markup %</Typography>
							<ControlledTextField
								name={'markupPercentage'}
								isNumber
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
									pattern: {
										value: /^-?\d*\.?\d+$/,
										message: 'El valor debe ser numérico',
									},
								}}
								onChange={(value) => handleMarkupPercentageChange(parseFloat(value))}
								helperText={checkShowErrorMessage(Boolean(errors.markupPercentage), errors.markupPercentage?.message)}
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Typography color={'Grey'}>Venta</Typography>
							<ControlledTextField
								name={'saleAmount'}
								isNumber
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
									pattern: {
										value: /^(0|[1-9]\d*)(\.\d+)?$/,
										message: 'El valor debe ser numérico y mayor o igual a 0',
									},
								}}
								onChange={(value) => handleSaleAmountChange(parseFloat(value))}
								helperText={checkShowErrorMessage(Boolean(errors.saleAmount), errors.saleAmount?.message)}
							/>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={12} sm={3}>
							<Typography color={'Grey'}>Costo Total</Typography>
							<ControlledTextField name={'totalCost'} disabled />
						</Grid>
						<Grid item xs={12} sm={4}>
							<Typography color={'Grey'}>Venta Total</Typography>
							<ControlledTextField name={'totalSaleAmount'} disabled />
						</Grid>
						{theCountry === 'CO' && (
							<>
								<Grid item xs={2} sm={2}>
									<Typography color={'Grey'}>Tipo de Cambio</Typography>
									<ControlledTextField
										name={'currencyRate'}
										disabled={isExistingService}
										onChange={calculateTotalCost}
									/>
								</Grid>
								<Grid item xs={10} sm={2}>
									<Typography color={'Grey'}>Total en COP</Typography>
									<ControlledTextField name={'totalSaleAmountInLocalCurrency'} disabled />
								</Grid>
							</>
						)}
					</Grid>
					{fields.map((_stretches, stretchesIndex) => (
						<React.Fragment key={`stretches-${stretchesIndex}`}>
							<Box display="flex" justifyContent="space-between" alignItems="end">
								<Box>
									<Typography sx={{ pt: 3, pl: 2, pb: 2 }} variant="h6">
										Tramo {stretchesIndex + 1}
									</Typography>
								</Box>
								<Box>
									<Button variant="contained" color="error" onClick={() => handleDelete(stretchesIndex)}>
										<DeleteIcon />
										Eliminar tramo
									</Button>
								</Box>
							</Box>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={4}>
									<Typography color={'Grey'}>Tipo de traslado</Typography>
									<ControlledSelect
										name={`transferSegments.${stretchesIndex}.transferType`}
										rules={{
											required: { value: true, message: 'Este campo es requerido' },
										}}
										options={typeTransfer.map((item) => ({ id: item.id.toString(), name: item.name }))}
										iconTypeTrip={iconType}
									/>
								</Grid>
								<Grid item xs={12} sm={3.7}>
									<Typography color={'Grey'}>Origen</Typography>
									<ControlledTextField
										name={`transferSegments.${stretchesIndex}.originName`}
										rules={{
											required: { value: true, message: 'Este campo es requerido' },
										}}
										helperText={checkShowErrorMessage(
											Boolean(errors.transferSegments?.[stretchesIndex]?.originName),
											errors.transferSegments?.[stretchesIndex]?.originName?.message,
										)}
									/>
								</Grid>
								<Grid xs={12} sm={0.6}>
									<ArrowForwardIcon sx={{ color: 'Grey', mt: 5.5, ml: 1.5 }} />
								</Grid>
								<Grid item xs={12} sm={3.7}>
									<Typography color={'Grey'}>Destino</Typography>
									<ControlledTextField
										name={`transferSegments.${stretchesIndex}.destinationName`}
										rules={{
											required: { value: true, message: 'Este campo es requerido' },
										}}
										helperText={checkShowErrorMessage(
											Boolean(errors.transferSegments?.[stretchesIndex]?.destinationName),
											errors.transferSegments?.[stretchesIndex]?.destinationName?.message,
										)}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Typography color={'Grey'}>Fecha inicial</Typography>
									<ControlledDatePicker
										name={`transferSegments.${stretchesIndex}.startDate`}
										rules={{ required: 'Este campo es requerido' }}
										required={true}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Typography color={'Grey'}>Fecha Vuelta / Limite</Typography>
									<ControlledDatePicker
										name={`transferSegments.${stretchesIndex}.endDate`}
										rules={{ required: 'Este campo es requerido' }}
										required={true}
									/>
								</Grid>
							</Grid>
						</React.Fragment>
					))}
					<Grid item xs={12}>
						<Button className={classes.buttonAdd} variant="outlined" onClick={addstretches}>
							+ Agregar tramo
						</Button>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Typography color={'Grey'}>Notas de voucher</Typography>
						<Controller
							// @ts-ignore
							name={'voucherNotes'}
							control={control}
							defaultValue="Favor de presentar voucher en el check-in"
							render={({ field }) => (
								<TextField
									fullWidth
									className={classes.textField}
									variant="outlined"
									multiline
									rows={5}
									{...field}
									helperText={checkShowErrorMessage(Boolean(errors.voucherNotes), errors.voucherNotes?.message)}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Typography color={'Grey'}>
							<LockIcon /> Notas internas
						</Typography>
						<Controller
							// @ts-ignore
							name={'internalNotes'}
							control={control}
							render={({ field }) => (
								<TextField
									fullWidth
									className={classes.textField}
									variant="outlined"
									multiline
									rows={5}
									{...field}
									helperText={checkShowErrorMessage(Boolean(errors.internalNotes), errors.internalNotes?.message)}
								/>
							)}
						/>
					</Grid>
					<ServiceCostVariationLogs filteredArray={filteredArray} />
				</Box>
				{(service.enable || service.enable === undefined) && (
					<Box display="flex" flexDirection="row-reverse" mt={2}>
						<Button type="submit" variant="contained" color="success">
							Guardar
						</Button>
					</Box>
				)}
			</form>
		</FormProvider>
	);
};

import React, { useEffect, useState } from 'react';
import {
	Alert,
	AlertColor,
	Backdrop,
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CircularProgress,
	Collapse,
	Grid,
	IconButton,
	Snackbar,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Toolbar,
	Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles/makeStyles';
import { extractErrorMessage, isNotFoundError } from 'features/quotation/helpers';
import { finalizeTransaction, getCardInfo, getTransactionDetails } from 'features/transactions/services';
import { ICardInfoResult, IFlagShowCardInfo, ITransactionDetails } from 'features/transactions/types';
import { CreateAuthorizationModal } from 'features/authorization/components/CreateAuthorizationModal';
import { ConfirmationModal } from 'features/common/components/ConfirmationModal/ConfirmationModal';
import { useHistory } from 'react-router-dom';
import CreateIcon from '@mui/icons-material/AddTask';
import TransactionIcon from '@mui/icons-material/AssignmentTurnedIn';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { RolesEnum } from 'features/common/types';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { formatDateTimeToShowUser, formatDateToShowUser } from 'features/common/helpers';
import { getAuthorizationStateName } from 'features/transactions/helpers';
import { ReturnButton } from 'features/common/components/ReturnButton';
import { CreateRefundAuthorizationModal } from '../CreateRefundAuthorizationModal';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { PageInternalError404, ErrorType } from '../../../common/components/PageInternalError404';
import DehazeIcon from '@mui/icons-material/Dehaze';
import { AuthorizationAmadeusFPCashCommandModal } from '../AuthorizationAmadeusFPCashCommandModal';

interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

interface TransactionDetailsProps {
	id: number;
}

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: '#fff',
	},
	cardItem: {
		backgroundColor: theme.palette.primary.main,
		color: 'white',
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'center',
		justifyContent: 'center',
	},
	tableHeader: {
		backgroundColor: theme.palette.primary.main,
		color: 'white',
	},
}));

export const TransactionDetails = ({ id }: TransactionDetailsProps): JSX.Element => {
	const classes = useStyles();
	const history = useHistory();
	const { auth } = useSelector((state: RootState) => state);
	const [loading, setLoading] = useState<boolean>(false);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
	const [transactionsDetails, setTransactionsDetails] = useState<ITransactionDetails | null>(null);
	const [flagShowCardInfo, setFlagShowCardInfo] = useState<IFlagShowCardInfo>({
		showCreditCardNumber: false,
		showSecurityCode: false,
	});
	const [showCreateAuthorizationModal, setShowCreateAuthorizationModal] = useState<boolean>(false);
	const [transactionAmountSum, setTransactionAmountSum] = useState<number>(0);
	const [showFinalizeTransactionModal, setShowFinalizeTransactionModal] = useState<boolean>(false);
	const [showRefundAuthorizationTransactionModal, setShowRefundAuthorizationModal] = useState<boolean>(false);
	const [cardInfo, setCardInfo] = useState<ICardInfoResult | null>(null);
	const [seeCardInfo, setSeeCardInfo] = useState<boolean>(false);
	const [open, setOpen] = React.useState(false);
	const isAdminOrSuperAdminOrOperator =
		auth.user?.role === RolesEnum.SUPERADMIN ||
		auth.user?.role === RolesEnum.ADMIN ||
		auth.user?.role === RolesEnum.OPERATOR;
	const [selectedAuthorizationId, setSelectedAuthorizationId] = useState<number>();
	const [openModal, setOpenModal] = React.useState(false);
	const [showError404, setShowError404] = useState<boolean>(false);

	const handleOpen = (authorizationId: number) => {
		setSelectedAuthorizationId(authorizationId);
		setOpenModal(true);
	};
	const handleClose = () => {
		setOpenModal(false);
	};
	const manageFlagShowCardInfo = (flagShowCardInfo: IFlagShowCardInfo) => {
		setFlagShowCardInfo(flagShowCardInfo);
		if (!flagShowCardInfo.showCreditCardNumber && !flagShowCardInfo.showSecurityCode) {
			setSeeCardInfo(false);
			setAlert({
				show: true,
				severity: 'warning',
				message: 'No es permitido mostrar la información de la tarjeta para esta transacción',
			});
		}
	};

	const loadCardInfo = async () => {
		try {
			if (isAdminOrSuperAdminOrOperator && seeCardInfo) {
				setLoading(true);
				const loadedCardInfo = (await getCardInfo(id)).data;
				setLoading(false);
				setCardInfo(loadedCardInfo);
				manageFlagShowCardInfo(loadedCardInfo.flagShowCardInfo);
			}
		} catch (error) {
			setLoading(false);
			let errorMessage = '';
			if (isNotFoundError(error)) {
				errorMessage = 'No se encontró la tarjeta';
			} else {
				errorMessage = extractErrorMessage(error, 'Ocurrió un error al recibir los detalles de la tarjeta');
			}
			setAlert({
				show: true,
				severity: 'error',
				message: errorMessage,
			});
		}
	};

	const getDetails = async () => {
		try {
			setLoading(true);
			const loadedTransactionDetails = (await getTransactionDetails(id)).data;
			setTransactionsDetails(loadedTransactionDetails);
			manageFlagShowCardInfo(loadedTransactionDetails.flagShowCardInfo);
			const transactionAmount = loadedTransactionDetails.authorizationsData.reduce((sum, authorization) => {
				return sum + authorization.amountAuthorized;
			}, 0);
			setTransactionAmountSum(transactionAmount);
			setLoading(false);
		} catch (error) {
			if (isNotFoundError(error)) {
				setLoading(false);
				setShowError404(true);
			} else {
				setAlert({
					show: true,
					severity: 'error',
					message: extractErrorMessage(error, 'Ocurrió un error al recibir los detalles de transacciones'),
				});
				setLoading(false);
			}
		}
	};

	useEffect(() => {
		getDetails();
	}, []);

	useEffect(() => {
		loadCardInfo();
	}, [seeCardInfo]);

	const handleCreate = () => {
		setShowCreateAuthorizationModal(true);
	};

	const handleFinalizeTransaction = async () => {
		try {
			setLoading(true);
			await finalizeTransaction(id);
			setAlert({
				show: true,
				severity: 'success',
				message: 'La transacción se finalizó con éxito',
			});
			setShowFinalizeTransactionModal(false);
			setSeeCardInfo(false);
			setLoading(false);
		} catch (error) {
			setShowFinalizeTransactionModal(false);
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al finalizar la transacción'),
			});
			setLoading(false);
		}
	};

	if (showError404) {
		return <PageInternalError404 errorType={ErrorType.INTERNAL_TRANSACTION_NOT_FOUND} />;
	}

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>

			{transactionsDetails && (
				<>
					{' '}
					<Grid container gap={2}>
						<Grid item xs={12}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Grid container spacing={2}>
										<Grid item zeroMinWidth xs={12}>
											<Card>
												<CardContent>
													<Toolbar disableGutters sx={{ display: 'flex', justifyContent: 'space-between' }}>
														<Typography variant="h5" color="primary">
															Informacion de Tarjeta
														</Typography>
														<ReturnButton />
													</Toolbar>
													<Grid container>
														{isAdminOrSuperAdminOrOperator && cardInfo && seeCardInfo ? (
															<Grid item xs={6}>
																<Typography color="text.secondary">Número de Tarjeta</Typography>
																<Typography variant="body2">{cardInfo.cardNumber}</Typography>
																<Typography color="text.secondary">Código de Seguridad</Typography>
																<Typography variant="body2">{cardInfo.cardSecurityCode}</Typography>
															</Grid>
														) : (
															<Grid item xs={6}>
																<Typography color="text.secondary">Número de Tarjeta</Typography>
																<Typography variant="body2">
																	{transactionsDetails.transactionData.cardNumberFormatted}
																</Typography>
																<Typography color="text.secondary">Código de Seguridad</Typography>
																<Typography variant="body2">
																	{transactionsDetails.transactionData.cardSecurityCodeFormatted}
																</Typography>
															</Grid>
														)}
														<Grid item xs={6}>
															<Typography color="text.secondary">Fecha de Expiración</Typography>
															<Typography variant="body2">
																{transactionsDetails.transactionData.cardExpiration}
															</Typography>
															<Typography color="text.secondary">Sello</Typography>
															<Typography variant="body2">{transactionsDetails.transactionData.cardStamp}</Typography>
														</Grid>
													</Grid>
												</CardContent>
												{isAdminOrSuperAdminOrOperator && (
													<CardActions>
														<Button
															size="small"
															disabled={!flagShowCardInfo.showCreditCardNumber && !flagShowCardInfo.showSecurityCode}
															onClick={() => setSeeCardInfo(!seeCardInfo)}
															variant="contained"
															endIcon={seeCardInfo ? <Visibility /> : <VisibilityOff />}
														>
															{seeCardInfo ? 'Ocultar Información de Tarjeta' : 'Ver Información de Tarjeta'}
														</Button>
													</CardActions>
												)}
											</Card>
										</Grid>
										<Grid item xs={12}>
											<Grid container spacing={1}>
												<Grid item md={4} xs={12}>
													<Card sx={{ minWidth: 275 }}>
														<CardContent>
															<Typography variant="h5" component="div">
																ID de Transacción
															</Typography>
															<Typography variant="body2">{transactionsDetails.transactionData.id}</Typography>
														</CardContent>
													</Card>
												</Grid>
												<Grid item md={4} xs={12}>
													<Card sx={{ minWidth: 275 }}>
														<CardContent>
															<Typography variant="h5" component="div">
																{transactionsDetails.transactionData.isBitrixDeal ? 'Deal ID' : 'Caso'}
															</Typography>
															<Typography variant="body2">{transactionsDetails.transactionData.crmTicket}</Typography>
														</CardContent>
													</Card>
												</Grid>
												<Grid item md={4} xs={12}>
													<Card sx={{ minWidth: 275 }}>
														<CardContent>
															<Typography variant="h5" component="div">
																File
															</Typography>
															<Typography variant="body2">
																{transactionsDetails.transactionData?.file
																	? transactionsDetails.transactionData?.file
																	: '-'}
															</Typography>
														</CardContent>
													</Card>
												</Grid>
												<Grid item md={4} xs={12}>
													<Card sx={{ minWidth: 275 }}>
														<CardContent>
															<Typography variant="h5" component="div">
																Titular
															</Typography>
															<Typography variant="body2">{transactionsDetails.transactionData.cardholder}</Typography>
														</CardContent>
													</Card>
												</Grid>
												<Grid item md={4} xs={12}>
													<Card sx={{ minWidth: 275 }}>
														<CardContent>
															<Typography variant="h5" component="div">
																Cuotas
															</Typography>
															<Typography variant="body2">{transactionsDetails.transactionData.fee}</Typography>
														</CardContent>
													</Card>
												</Grid>
												<Grid item md={4} xs={12}>
													<Card sx={{ minWidth: 275 }}>
														<CardContent>
															<Typography variant="h5" component="div">
																Moneda
															</Typography>
															<Typography variant="body2">{transactionsDetails.transactionData.coinCode}</Typography>
														</CardContent>
													</Card>
												</Grid>
												<Grid item md={4} xs={12}>
													<Card sx={{ minWidth: 275 }}>
														<CardContent>
															<Typography variant="h5" component="div">
																Monto
															</Typography>
															<Typography variant="body2">{transactionsDetails.transactionData.amount}</Typography>
														</CardContent>
													</Card>
												</Grid>
												<Grid item md={4} xs={12}>
													<Card sx={{ minWidth: 275 }}>
														<CardContent>
															<Typography variant="h5" component="div">
																Pendiente de Autorizar
															</Typography>
															<Typography variant="body2">
																{transactionsDetails.transactionData.pendingAuthorization}
															</Typography>
														</CardContent>
													</Card>
												</Grid>
												<Grid item md={4} xs={12}>
													<Card sx={{ minWidth: 275 }}>
														<CardContent>
															<Typography variant="h5" component="div">
																Pendiente de Cruzar
															</Typography>
															<Typography variant="body2">
																{transactionsDetails.transactionData.pendingToCross}
															</Typography>
														</CardContent>
													</Card>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Grid container marginBottom={1}>
										<Grid container xs={12}>
											<Grid item xs={4}>
												<Typography variant="h5">Autorizaciones</Typography>
											</Grid>

											<Grid item xs={8} display="flex" justifyContent={'flex-end'} gap={1}>
												<Button onClick={handleCreate} variant="contained" endIcon={<CreateIcon />}>
													Adicionar Autorización
												</Button>
												<Button
													color="warning"
													variant="contained"
													endIcon={<TransactionIcon />}
													onClick={() => setShowFinalizeTransactionModal(true)}
												>
													Finalizar Transacción
												</Button>
												<Button
													color="warning"
													variant="contained"
													endIcon={<CurrencyExchangeIcon />}
													onClick={() => setShowRefundAuthorizationModal(true)}
												>
													Devolución
												</Button>
											</Grid>
										</Grid>
									</Grid>
									<TableContainer component="main" sx={{ maxWidth: 'xl', maxHeight: 500 }}>
										<div>
											<Table stickyHeader size="small">
												<TableHead>
													<TableRow>
														<TableCell className={classes.tableHeader} align="right"></TableCell>
														<TableCell className={classes.tableHeader} align="right">
															ID
														</TableCell>
														<TableCell className={classes.tableHeader} align="right">
															Cobrado contra
														</TableCell>
														<TableCell className={classes.tableHeader} align="right">
															Monto Autorizado
														</TableCell>

														<TableCell className={classes.tableHeader} align="right">
															Código de Autorización
														</TableCell>
														<TableCell className={classes.tableHeader} align="right">
															Fecha de Pago
														</TableCell>
														<TableCell className={classes.tableHeader} align="right">
															Fecha de Vto. de Pago
														</TableCell>
														<TableCell className={classes.tableHeader} align="right">
															Pendiente a Cruzar
														</TableCell>
														<TableCell className={classes.tableHeader} align="right">
															Estado
														</TableCell>
														<TableCell className={classes.tableHeader} align="right">
															Cruzado contra
														</TableCell>
														<TableCell className={classes.tableHeader} align="right">
															Fecha de Creación
														</TableCell>
														<TableCell className={classes.tableHeader} align="right"></TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{transactionsDetails?.authorizationsData.length ? (
														transactionsDetails?.authorizationsData.map((authorization) => (
															<>
																<TableRow
																	key={authorization.id}
																	hover
																	onDoubleClick={() => history.push(`/authorization/${+authorization.id}`)}
																	sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
																>
																	{authorization.refunded.length > 0 && (
																		<TableCell>
																			<IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
																				{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
																			</IconButton>
																		</TableCell>
																	)}
																	{authorization.refunded.length == 0 && <TableCell></TableCell>}
																	<TableCell align="justify" component="p">
																		{authorization.id}
																	</TableCell>
																	<TableCell align="justify" component="p">
																		{authorization.chargedAgainstOptionName}
																	</TableCell>
																	<TableCell align="justify" component="p">
																		{authorization.amountAuthorized} {authorization.coinCode}
																	</TableCell>

																	<TableCell align="right" component="p">
																		{authorization.authorizationCode}
																	</TableCell>
																	<TableCell align="right" component="p">
																		{formatDateToShowUser(authorization.paymentDate)}
																	</TableCell>
																	<TableCell align="right" component="p">
																		{formatDateToShowUser(authorization.paymentDueDate)}
																	</TableCell>
																	<TableCell align="right" component="p">
																		{authorization.pendingToCross}
																	</TableCell>
																	<TableCell align="right" component="p">
																		{getAuthorizationStateName(authorization.state)}
																	</TableCell>
																	<TableCell align="right" component="p">
																		{authorization.operatorInFirstCrossNotUnbind
																			? authorization.operatorInFirstCrossNotUnbind
																			: '-'}
																	</TableCell>
																	<TableCell align="right" component="p">
																		{formatDateTimeToShowUser(authorization.createdAt)}{' '}
																	</TableCell>
																	<TableCell align="right">
																		{isAdminOrSuperAdminOrOperator && (
																			<>
																				<Button
																					onClick={() => handleOpen(authorization.id)}
																					variant="contained"
																					title="Click para generar comando de amadeus"
																				>
																					<DehazeIcon />
																				</Button>
																				{openModal && (
																					<AuthorizationAmadeusFPCashCommandModal
																						open={openModal}
																						handleClose={handleClose}
																						authorizationId={selectedAuthorizationId}
																					/>
																				)}
																			</>
																		)}
																	</TableCell>
																</TableRow>
																<TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
																	<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
																		<Collapse in={open} timeout="auto" unmountOnExit>
																			<Box sx={{ margin: 1 }}>
																				<Typography
																					variant="h6"
																					gutterBottom
																					component="div"
																					sx={{ textAlign: 'center' }}
																				>
																					Devoluciones asociadas
																				</Typography>
																				<Table size="small" aria-label="Devoluciones">
																					<TableHead>
																						<TableRow>
																							<TableCell>Cantidad</TableCell>
																							<TableCell>Fecha de Devolución</TableCell>
																							<TableCell>Devuelto por</TableCell>
																						</TableRow>
																					</TableHead>
																					<TableBody>
																						{authorization.refunded.map((refunded) => (
																							<TableRow key={authorization.id + refunded.refundedAt}>
																								<TableCell component="th" scope="row">
																									{refunded.amountRefunded} {refunded.coinCode}
																								</TableCell>
																								<TableCell>{formatDateTimeToShowUser(refunded.refundedAt)}</TableCell>
																								<TableCell>{refunded.refundedBy}</TableCell>
																							</TableRow>
																						))}
																					</TableBody>
																				</Table>
																			</Box>
																		</Collapse>
																	</TableCell>
																</TableRow>
															</>
														))
													) : (
														<TableRow>
															<TableCell align="center" colSpan={10}>
																No hay autorizaciones que mostrar aún
															</TableCell>
														</TableRow>
													)}
												</TableBody>
											</Table>
										</div>
									</TableContainer>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<CreateAuthorizationModal
						reloadList={getDetails}
						transactionId={transactionsDetails?.transactionData?.id}
						open={showCreateAuthorizationModal}
						handleClose={() => setShowCreateAuthorizationModal(false)}
					/>
					<ConfirmationModal
						message={`Esta acción finalizará la transacción con el monto de ${transactionAmountSum}`}
						open={showFinalizeTransactionModal}
						handleClose={() => setShowFinalizeTransactionModal(false)}
						handleAccept={handleFinalizeTransaction}
					/>
					<CreateRefundAuthorizationModal
						reloadList={getDetails}
						open={showRefundAuthorizationTransactionModal}
						handleClose={() => setShowRefundAuthorizationModal(false)}
						transactionId={id}
					/>
				</>
			)}
			<Snackbar open={alert.show} autoHideDuration={2500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};

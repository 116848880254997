import { HotelDetail } from '../hotels/types';
import { GetPAXTotalsResponse, ServicePriceResponse } from './helpers';

export type roomServiceType = 'onlyRoom' | 'breakfast' | 'allInclusive' | 'fullPension' | 'halfPension';
export type hotelProviderType = 'MeVuelo' | 'Hotelbeds' | 'W2M';
export type roomRateType = 'nonRefundable' | 'refundable';
export type serviceType = 'perPassenger' | 'perGroup';

export interface ServiceOptionFareDetail {
	id: number;
	from: string;
	to: string;
	fareSgl: number;
	qtySgl: number;
	fareDbl: number;
	qtyDbl: number;
	fareTpl: number;
	qtyTpl: number;
	fareCpl: number;
	qtyCpl: number;
	fareChd: number;
	qtyChd: number;
	currency: string;
}

export interface ServiceOption {
	id: number;
	name: string;
	forAllDestinations: boolean;
	type: string;
	destinations: DestinationSuggestion[];
	observations: string;
	fares: ServiceOptionFareDetail[];
}

export interface ServicePayload {
	id: string;
	name: string;
	serviceType: serviceType;
	city: string;
}

export interface RoomBaseConfig {
	id?: number;
	adults: number;
	childs: number[];
	infants: number[];
}

export interface Service {
	fareCost: number;
	serviceType: serviceType;
	name: string;
	description?: string;
	chdCharged: number;
	infCharged: number;
}

export interface RoomFare {
	price: number;
	board: roomServiceType;
	rateType: roomRateType;
	description?: string;
}

export interface MainImage {
	url: string;
}
export interface Images {
	photo: MainImage[];
}
export interface HotelOptions {
	hotelId: number;
	provider: hotelProviderType;
	hotelName: string;
	chdCharged: number;
	infCharged: number;
	rooms: RoomFare[];
	details: HotelDetail;
	brokerName?: string | undefined;
}

export interface OptionalService {
	id: number;
	name: string;
	description: string;
	imageUrl: string;
	show: boolean;
	url: string | undefined;
	adultPrice: number;
	childPrice: number | undefined;
	infantPrice: number | undefined;
}

export interface CommonQuotationDestinationInfo {
	cityCode: string;
	cityName: string;
	nights: number;
	checking: string;
}
export interface CommonQuotationDestinationInfoWithOptionalServices extends CommonQuotationDestinationInfo {
	optionalServices: OptionalService[];
}

export interface Destination extends CommonQuotationDestinationInfoWithOptionalServices {
	id?: number;
	cityCode: string;
	cityName: string;
	nights: number;
	checking: string;
	services: Service[];
	hotelOptions: HotelOptions[];
	optionalServices: OptionalService[];
}

export interface QuotationFlightImage {
	id: string;
	image: string;
	order: number;
}

export interface Air {
	id?: number;
	fareADT: number;
	fareCHD: number;
	fareINF: number;
	validatingCarrier: string;
	baggage: string;
	carryOn: boolean;
	tst: string;
	images: QuotationFlightImage[];
	data?: QuotationFlightInfo[];
}

export interface roomMarkup {
	destinationKey: number;
	roomIndex: number;
	value: number;
	pvpADT: number;
	pvpCHD: number;
	pvpINF: number;
}

export interface QuotationOption {
	destinationKey: number;
	hotelCombinations: number[];
	selectedServices: number[];
	hotelPrice: number;
	flightPrice: number;
	servicesPrice: number;
	roomMarkup: roomMarkup;
}

export interface QuotationGeneralInfo {
	crmTicket: number;
	isBitrixDeal: boolean;
	description: string;
	name: string;
	email: string | null;
	phone: string;
}

export interface Quotation extends QuotationGeneralInfo {
	id: number;
	crmTicket: number;
	isBitrixDeal: boolean;
	description: string;
	agentId?: number;
	name: string;
	email: string | null;
	phone: string;
	roomBaseConfig: RoomBaseConfig[];
	notIncludes: string[];
	manualServices: ManualService[];
	sign: boolean;
	destination: Destination[];
	airs: Air[];
	markup: number;
	quotations: QuotationOption[];
	loading: boolean;
	wasCopied?: boolean;
	showLocalCurrency: boolean;
	exchangeRate: number;
	localCurrency: string;
}

export interface QuotationDefaultDescription {
	description: string;
}

export interface QuotationFlightInfo {
	flightNumber: string;
	airline: QuotationAirlineList;
	departure: QuotationFlightInfoStep;
	arrival: QuotationFlightInfoStep;
}

export interface QuotationAirlineList {
	code: string;
	name: string;
	image: string;
}

export interface QuotationFlightInfoStep {
	airport: QuotationAirportList;
	date: string;
	hour: string;
}

export interface QuotationAirportList {
	code: string;
	name: string;
	cityName: string;
}

export interface DestinationSuggestion {
	code: string;
	name: string;
}

export interface DestinationNameAndNights {
	destinationName: string;
	nights: number;
}

export interface QuotationsListItem {
	id: number;
	crmTicket: number;
	isBitrixDeal: boolean;
	description: string;
	isOnlyFlights: boolean;
	name: string;
	email: string;
	phone: number;
	markup: number;
	agentName: string;
	created: string;
	air: Air | null;
	wasCopied: boolean;
	destinationNamesAndNights: DestinationNameAndNights[];
	roomDistributions?: QuotationItemRoomDistribution[];
}

export interface QuotationRoomDistributionFilter {
	totalAdults: number;
	totalChildren?: number | null;
	totalInfants?: number | null;
}

export interface QuotationItemRoomDistribution {
	childrenAges: number[];
	infantsAges: number[];
	totalAdults: number;
	totalChildren: number;
	totalInfants: number;
}

export interface DestinationToAdd extends CommonQuotationDestinationInfo {
	cityCode: string;
	cityName: string;
	nights: number;
	checking: string;
	optionalServices: OptionalService[];
}

export interface CustomBoard {
	code: string;
	name: string;
}

export interface ManualService extends QuotationServiceSimple {
	fareCost: number;
	serviceType: serviceType;
	name: string;
	description?: string;
	chdCharged: number;
	infCharged: number;
}

/** Begin types fare request*/

export interface QuotationRoomDistributionFaresRequest {
	adults: number;
	childrenAges: number[];
}

export interface QuotationRoomDistribution {
	adults: number;
	childrenAges: number[];
}

export interface Board {
	code: string;
	name: string;
}

export type CancellationPolicy = 'CLX' | 'NRF';

export interface AccommodationFaresRoomPrice {
	board: Board;
	cancellationPolicy: CancellationPolicy;
	fare: number;
	currency: string;
}

export interface Room {
	code: string;
	name: string;
}
export interface AccommodationFaresByRoom {
	room: Room;
	fares: AccommodationFaresRoomPrice[];
}

export interface AccommodationFaresByDistribution {
	distribution: QuotationRoomDistribution;
	faresByRoom: AccommodationFaresByRoom[];
}

export interface AccommodationFaresRequest {
	hotelId: number;
	provider: hotelProviderType;
	distributions: QuotationRoomDistributionFaresRequest[];
	/** ISO String Date */
	checkIn: string;
	/** ISO DString ate */
	checkOut: string;
}

export interface AccommodationFaresResponse {
	hotelId: number;
	hotelName: string;
	checkIn?: string;
	checkOut?: string;
	faresByDistribution: AccommodationFaresByDistribution[];
}

/** End types fare request*/

/**Begin type quotation to client */
export interface QuotationServiceSimple {
	name: string;
	description?: string;
}

export interface HotelOptionsSimple {
	hotelId: number;
	provider: hotelProviderType;
	hotelName: string;
	rooms: QuotationRoomWithPrice[];
	details: HotelDetail | undefined;
	flightIndex?: number;
}

export interface AirSimple {
	validatingCarrier: string;
	baggage: string;
	carryOn: boolean;
	data: QuotationFlightInfo[];
	images: QuotationFlightImage[];
}
export interface DestinationSimple extends CommonQuotationDestinationInfoWithOptionalServices {
	cityCode: string;
	cityName: string;
	nights: number;
	checking: string;
	services: QuotationServiceSimple[];
	hotelOptions: HotelOptionsSimple[];
	optionalServices: OptionalService[];
}

export interface QuotationRoomSign {
	adtSign: number;
	chdSign: number;
	infSign: number;
	totalSign: number;
}

export interface QuotationRoomWithPrice {
	board: roomServiceType;
	rateType: roomRateType;
	adtPrice: number;
	chdPrice: number;
	infPrice: number;
	totalPrice: number;
	description?: string;
	sign: QuotationRoomSign | null;
}

export interface BaseQuotationSnapShotToClient {
	id: number;
	crmTicket: number;
	isBitrixDeal: boolean;
	description: string;
	name: string;
	email: string | null;
	phone: string;
	countryCode: string;
	notIncludes: string[];
	airs: AirSimple[];
	quotationType: QuotationType;
}

export interface BaseQuotationSnapShotToClientNotOnlyFlights extends BaseQuotationSnapShotToClient {
	id: number;
	crmTicket: number;
	description: string;
	name: string;
	email: string | null;
	phone: string;
	countryCode: string;
	notIncludes: string[];
	roomBaseConfig: RoomBaseConfig[];
	manualServices: QuotationServiceSimple[];
	airs: AirSimple[];
	paxTotal: GetPAXTotalsResponse;
	quotationType: QuotationType;
}

export interface QuotationSnapShotToClient extends BaseQuotationSnapShotToClientNotOnlyFlights {
	id: number;
	crmTicket: number;
	description: string;
	name: string;
	email: string | null;
	phone: string;
	countryCode: string;
	notIncludes: string[];
	roomBaseConfig: RoomBaseConfig[];
	manualServices: QuotationServiceSimple[];
	airs: AirSimple[];
	paxTotal: GetPAXTotalsResponse;
	destination: DestinationSimple[];
	showLocalCurrency: boolean;
}

export enum QuotationType {
	SIMPLEDESTINATION = 1,
	MULTIDESTINATION = 2,
	ONLYFLIGHTS = 3,
}

export interface QuotationInfoToSendClient {
	id: string;
	quotationType: QuotationType;
	quotationSnapShotToClient:
		| QuotationSnapShotToClient
		| QuotationSnapShotToClientDestinationMultiple
		| QuotationSnapShotToClientOnlyFlights;
	message: string;
	sendLink: boolean;
}

export interface QuotationInfoToSendClientResponse {
	urlToClient: string;
}

/**End types quotation to client */

export interface AutocompleteContactData {
	name: string;
	email?: string;
	phone?: string;
}

export interface DestinationList {
	code: string;
	name: string;
}

export interface InputCopyQuotation extends QuotationGeneralInfo {
	quotationIdToCopy: number;
	isOnlyFlights: boolean;
}

/**Quotation multidestino**/

export interface HotelOptionDetailDestinationMultiple {
	hotelId: number;
	provider: hotelProviderType;
	hotelName: string;
	chdCharged: number;
	infCharged: number;
	rooms: RoomFare[];
	details: HotelDetail;
	boardsKey: string;
}

export interface DestinationSummaryForDestinationMultiple extends CommonQuotationDestinationInfoWithOptionalServices {
	cityCode: string;
	cityName: string;
	nights: number;
	checking: string;
	services: QuotationServiceSimple[];
	hotelOptions: HotelOptionDetailDestinationMultiple[];
	optionalServices: OptionalService[];
}

export interface DestinationForCombine {
	cityCode: string;
	cityName: string;
	nights: number;
	checking: string;
	services: QuotationServiceSimple[];
	optionalServices: OptionalService[];
	servicesPrice: ServicePriceResponse;
}

export interface HotelOptionsContainerForCombine {
	hotelOption: HotelOptions;
	destination: DestinationForCombine;
	destinationIndex: number;
}

export interface QuotationDestinationMultipleRoomWithPrice {
	boards: roomServiceType[];
	rateTypes: roomRateType[];
	adtPrice: number;
	chdPrice: number;
	infPrice: number;
	totalPrice: number;
	sign: QuotationRoomSign | null;
}

export interface QuotationDestinationMultipleSummaryPriceCombinationKey {
	destinationIndex: number;
	destinationCode: string;
	hotelId: number;
	hotelProvider: hotelProviderType;
	boardsKey: string;
	flightIndex?: number;
}

export interface QuotationDestinationMultipleSummaryPriceCombination {
	keys: QuotationDestinationMultipleSummaryPriceCombinationKey[];
	roomsSummaryPrice: QuotationDestinationMultipleRoomWithPrice[];
	summaryPriceForOneAdult: number;
}

export interface QuotationDestinationMultipleContainer {
	priceCombinations: QuotationDestinationMultipleSummaryPriceCombination[];
	destinations: DestinationSummaryForDestinationMultiple[];
}

export interface QuotationSnapShotToClientDestinationMultiple extends BaseQuotationSnapShotToClientNotOnlyFlights {
	id: number;
	crmTicket: number;
	description: string;
	name: string;
	email: string;
	phone: string;
	countryCode: string;
	notIncludes: string[];
	roomBaseConfig: RoomBaseConfig[];
	manualServices: QuotationServiceSimple[];
	airs: AirSimple[];
	paxTotal: GetPAXTotalsResponse;
	containerDestinationMultiple: QuotationDestinationMultipleContainer;
	showLocalCurrency?: boolean;
}

export interface QuotationDefaultNotInclude {
	notInclude: string;
	id?: number;
}

export interface QuotationDestinationList {
	code: string;
	name: string;
	regionName: string;
	image?: string;
}
export interface QuotationRegion {
	name: string;
	destinations: QuotationDestinationList[];
	icon?: string;
}

export enum QuotationFieldsMaxLength {
	DESCRIPTION = 2000,
	NOTINCLUDES = 3000,
}

/**End Quotation multidestino**/

export interface QuotationFlightInfoFromAutomaticRoute {
	fareADT: number;
	fareCHD: number;
	fareINF: number;
	validatingCarrier: string;
	baggage: string;
	carryOn: boolean;
	flightInfos: QuotationFlightInfo[];
}

export interface IHotelMostQuoted {
	destinationCode: string;
	hotelId: number;
	hotelProvider: hotelProviderType;
	totalQuoted: number;
	order: number;
}
export interface IPassengerConfig {
	adults: number;
	childrenAges: number[];
	infantAges: number[];
}
export interface DestinationToAddQuotationOnlyFlights extends CommonQuotationDestinationInfo {
	cityCode: string;
	cityName: string;
	nights: number;
	checking: string;
}
export interface DestinationOnlyFlights extends CommonQuotationDestinationInfo {
	id?: number;
	cityCode: string;
	cityName: string;
	nights: number;
	checking: string;
}

export interface QuotationOnlyFlights extends QuotationGeneralInfo {
	id: number;
	crmTicket: number;
	isBitrixDeal: boolean;
	description: string;
	agentId?: number;
	name: string;
	email: string | null;
	phone: string;
	passengerConfig: IPassengerConfig | null;
	notIncludes: string[];
	destination: DestinationOnlyFlights[];
	airs: Air[];
	loading: boolean;
	markup: number;
	wasCopied?: boolean;
	showLocalCurrency: boolean;
}

export interface IFlightFinalPriceSummary {
	adtPrice: number;
	chdPrice: number;
	infPrice: number;
	totalPrice: number;
}

export interface QuotationSnapShotToClientOnlyFlights extends BaseQuotationSnapShotToClient {
	id: number;
	crmTicket: number;
	description: string;
	name: string;
	email: string | null;
	phone: string;
	countryCode: string;
	notIncludes: string[];
	airs: AirSimple[];
	passengerConfig: IPassengerConfig;
	destination: DestinationOnlyFlights[];
	flightFinalPriceSummaries: IFlightFinalPriceSummary[];
	exchange: number;
	localCurrency: string;
	showLocalCurrency?: boolean;
}

export interface IQuotationSaleReportList {
	crmTicket: number;
	isBitrixDeal: boolean;
	quotationId: number;
	serviceType: number;
	createdAt: string;
	travelDateQuoted: string;
	destinationsNames: string;
	applySign: boolean;
	markup: number;
	totalAdults: number;
	totalChild: number;
	totalInfant: number;
	totalAdultsAndChildren: number;
	validatingCarriesCodes?: string;
	sellerName: string;
}

export enum QuotationSaleReportServiceTypeEnum {
	HOTEL = 1,
	PACKAGE,
	FLIGHT,
}

export interface IQuotationSaleReportServiceType {
	id: QuotationSaleReportServiceTypeEnum;
	name: string;
}

export const quotationSaleReportServiceTypes: IQuotationSaleReportServiceType[] = [
	{
		id: QuotationSaleReportServiceTypeEnum.HOTEL,
		name: 'Hotel',
	},
	{
		id: QuotationSaleReportServiceTypeEnum.PACKAGE,
		name: 'Paquete',
	},
	{
		id: QuotationSaleReportServiceTypeEnum.FLIGHT,
		name: 'Vuelo',
	},
];

// src/constants/baggageTypes.ts

export const BaggageTypes = {
	NO_BAGGAGE: 'none',
	ONE_X_23K: '1x23k',
	TWO_X_23K: '2x23k',
} as const;

export type BaggageType = (typeof BaggageTypes)[keyof typeof BaggageTypes];

export type GenerateTravelInfoRequest = {
	destino: {
		nombre: string;
		codigo_iata: string;
	};
	pais_de_origen: {
		nombre: string;
		codigo_pais: string;
	};
	fechas_de_viaje: {
		inicio: string;
		fin: string;
	};
	travel_type: string;
	response: string[];
};

export type GenerateTravelInfoResponse = {
	message: string;
	data: {
		clima: string;
		puntos_de_interes: string;
		zonas_de_alojamiento: string;
		transporte_y_transito: string;
		aduanas_o_migraciones: string;
		como_llegar_desde_aeropuerto: string;
	};
	cityName?: string;
};
